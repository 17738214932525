import {
  DSS4DiseaseRequest as DSS4DiseaseReq,
  DSS4DiseaseResponse,
  DSS4StoryRequest as DSS4StoryReq,
  DSS4StoryResponse
} from '~/types/case'

import { Action, RequestPayload, SuccessPayload } from '../core/actions'
import DSS4Type from './actionTypes'

// TODO ぱらめーたーふやす
export type DSS4DiseaseRequestInput = {
  search: string[]
}
export type DSS4DiseaseRequest = {} & RequestPayload<DSS4DiseaseReq>
export type DSS4DiseaseSuccess = {} & SuccessPayload<DSS4DiseaseResponse>
export type DSS4DiseaseFailure = {}

export const requestDSS4Disease = (
  payload: DSS4DiseaseRequestInput
): Action<DSS4DiseaseRequest> => ({
  type: DSS4Type.REQUEST_DISEASE,
  payload: {
    body: {
      // age: null,
      db: '0',
      diseaseCount: 200,
      takeCount: 100,
      search: payload.search,
      searchSteps: 5,
      storyCount: 5,
      beforeStep: 0,
      maxPathRows: 3,
      // sex: null,
      enableLikeSearch: false
    }
  }
})

export const successDSS4Disease = (
  payload: DSS4DiseaseSuccess
): Action<DSS4DiseaseSuccess> => ({
  type: DSS4Type.SUCCESS_DISEASE,
  payload
})

export const failureDSS4Disease = (
  payload: DSS4DiseaseFailure
): Action<DSS4DiseaseFailure> => ({
  type: DSS4Type.FAILURE_DISEASE,
  payload
})

export type ScoreUpdateType = {
  disease: string
  minScore: number
}

/** スコアの更新 */
export const updateScore = (payload: {
  disease: string
  minScore: number
}): Action<ScoreUpdateType> => {
  // console.log('なんで', payload)
  return {
    type: DSS4Type.UPDATE_SCORE,
    payload
  }
}

export type DSS4DiseaseActionType = ReturnType<
  | typeof requestDSS4Disease
  | typeof successDSS4Disease
  | typeof failureDSS4Disease
  | typeof updateScore
>

export const diseaseActions = {
  requestDSS4Disease,
  successDSS4Disease,
  failureDSS4Disease,
  updateScore
}

// --------------

export type DSS4DiseaseStoryInput = {
  search: string[]
  disease: string
}
export type DSS4StoryRequest = {} & RequestPayload<DSS4StoryReq>
export type DSS4StorySuccess = {}
export type DSS4StoryFailure = { key: string }

export const requestDSS4Story = (
  payload: DSS4DiseaseStoryInput
): Action<DSS4StoryRequest> => ({
  type: DSS4Type.REQUEST_STORY,
  payload: {
    body: {
      age: null,
      beforeSteps: 1,
      db: '0',
      maxPathRows: 3,
      disease: payload.disease,
      search: payload.search,
      searchSteps: 7,
      sex: null,
      storyCount: 5,
      enableLikeSearch: false
    }
  }
})

export const successDSS4Story = (
  payload: DSS4StoryResponse
): Action<DSS4StoryResponse> => ({
  type: DSS4Type.SUCCESS_STORY,
  payload
})

export const failureDSS4Story = (
  payload: DSS4StoryFailure
): Action<DSS4StoryFailure> => ({
  type: DSS4Type.FAILURE_STORY,
  payload
})

export type DSS4StoryActionType = ReturnType<
  typeof requestDSS4Story | typeof successDSS4Story | typeof failureDSS4Story
>

export const storyActions = {
  requestDSS4Story,
  successDSS4Story,
  failureDSS4Story
}
