import { combineReducers } from 'redux'

import { initialInputState, inputs } from './input'
import { initialToggleState, toggle } from './toggle'

const uiReducers = combineReducers({
  toggle,
  inputs
})

export type UIState = ReturnType<typeof uiReducers>

export const initialState: UIState = {
  toggle: initialToggleState,
  inputs: initialInputState
}

export default uiReducers
