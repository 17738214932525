import { put } from 'redux-saga/effects'

import { RequestContext } from '~/types/network'
import { SuggestRequest } from '~/types/suggest'

import APIService from '../../service/api'
import { Action } from '../core/actions'
import { getToken } from '../core/saga'
import { suggestActions, SuggestSearhRequest } from './actions'
// import { FetchQueue } from '~/service/api/queue'

/**
 * サジェスト取得
 */
export function* getSuggest(args: Action<SuggestSearhRequest>) {
  // @ts-expect-error: TS7057: 'yield' expression implicitly results in an 'any'
  const token = yield getToken()

  const req: RequestContext<SuggestRequest> = {
    body: args.payload.body,
    token
  }
  let response
  try {
    // コイツキューに入れないか割り込みありで最優先する機能を組まないと DSS4 story 大量フェッチしてる間末尾に入って全体がめっちゃ重くなる
    // response = yield FetchQueue(APIService['suggest'], req)
    // @ts-expect-error: TS7057: 'yield' expression implicitly results in an 'any'
    response = yield APIService['suggest'](req)
    // console.log(response)
  } catch (error) {
    response = error
  }

  if (response.error) {
    yield put(suggestActions.failureSuggest({}))
  } else {
    yield put(suggestActions.successSuggest({ data: response }))
  }
}
