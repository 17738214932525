export const REQUEST_DISEASE = 'request_disease' as const
export const SUCCESS_DISEASE = 'success_disease' as const
export const FAILURE_DISEASE = 'failure_disease' as const

export const REQUEST_STORY = 'request_story' as const
export const SUCCESS_STORY = 'success_story' as const
export const FAILURE_STORY = 'failure_story' as const

export const UPDATE_SCORE = 'dss4_update_score' as const

export default {
  REQUEST_DISEASE,
  SUCCESS_DISEASE,
  FAILURE_DISEASE,
  REQUEST_STORY,
  SUCCESS_STORY,
  FAILURE_STORY,
  UPDATE_SCORE
}
