import { Reducer } from 'redux'

export const TOGGLE = 'ui_toggle_toggle' as const
export const OPEN = 'ui_toggle_open' as const
export const CLOSE = 'ui_toggle_close' as const

export const doToggle = (key: string) => ({
  type: TOGGLE,
  key
})

export const doOpen = (key: string) => ({
  type: OPEN,
  key
})

export const doClose = (key: string) => ({
  type: CLOSE,
  key
})

export type UIToggleActionType = ReturnType<
  typeof doToggle | typeof doOpen | typeof doClose
>

export interface UIToggleStateType {
  targets: {
    [key: string]: boolean
  }
}

export const initialToggleState: UIToggleStateType = {
  targets: {}
}

export const toggle: Reducer<UIToggleStateType, UIToggleActionType> = (
  state = initialToggleState,
  action
) => {
  switch (action.type) {
    case TOGGLE: {
      return {
        targets: toggleTargets(state.targets, action.key)
      }
    }
    case OPEN: {
      return {
        targets: openTargets(state.targets, action.key)
      }
    }
    case CLOSE: {
      return {
        targets: closeTargets(state.targets, action.key)
      }
    }
    default: {
      return state
    }
  }
}

// --------------------------
// selectors
// --------------------------

export const toggleTargets = (
  targets: { [key: string]: boolean },
  key: string
): { [key: string]: boolean } => {
  if (!(key in targets)) {
    targets[key] = true
  } else {
    targets[key] = !targets[key]
  }
  return {
    ...targets
  }
}

export const openTargets = (
  targets: { [key: string]: boolean },
  key: string
): { [key: string]: boolean } => {
  targets[key] = true
  return {
    ...targets
  }
}

export const closeTargets = (
  targets: { [key: string]: boolean },
  key: string
): { [key: string]: boolean } => {
  targets[key] = false
  return {
    ...targets
  }
}
