import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentProps,
  DialogProps,
  DialogTitle
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import React from 'react'

export interface UsageDialogProps {
  open: boolean
  onClose: () => void
  title?: React.ReactNode
  children: React.ReactNode
  maxWidth?: false | 'xl' | 'xs' | 'sm' | 'md' | 'lg' | undefined
  DialogProps?: Partial<DialogProps>
  DialogContentProps?: Partial<DialogContentProps>
}

const UsageDialog: React.FC<UsageDialogProps> = ({
  open,
  onClose,
  title,
  children,
  maxWidth = 'xl',
  DialogProps,
  DialogContentProps
}: UsageDialogProps) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Dialog
      onClose={onClose}
      open={open}
      fullScreen={fullScreen}
      maxWidth={maxWidth}
      {...DialogProps}
    >
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent dividers {...DialogContentProps}>
        {children}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose} color="primary">
          閉じる
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default UsageDialog
