export const REQUEST_POLICY = 'request_policy' as const
export const SUCCESS_POLICY = 'success_policy' as const
export const FAILURE_POLICY = 'failure_policy' as const

export const REQUEST_READ_POLICY = 'request_read_policy' as const
export const SUCCESS_READ_POLICY = 'success_read_policy' as const
export const FAILURE_READ_POLICY = 'failure_read_policy' as const

export default {
  REQUEST_POLICY,
  SUCCESS_POLICY,
  FAILURE_POLICY,
  REQUEST_READ_POLICY,
  SUCCESS_READ_POLICY,
  FAILURE_READ_POLICY
}
