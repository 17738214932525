import { MutableRefObject } from 'react'

/**
 * 渡された ref<HTMLElement> のスクロール位置を 0 にする
 */
export const refScrollReset = (
  refs: MutableRefObject<null | HTMLElement>[]
) => {
  refs.forEach((ref) => {
    if (!ref.current || !ref.current.scrollTo) {
      return
    }
    ref.current.scrollTo(0, 0)
  })
}
