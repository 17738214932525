import { Reducer } from 'redux'

export const INPUT = 'ui_input_input' as const
export const CLEAR = 'ui_input_clear' as const
export const FLUSH = 'ui_input_flush' as const

export const doInput = (key: string, value: string) => ({
  type: INPUT,
  key,
  value
})

export const doClear = (key: string) => ({
  type: CLEAR,
  key
})

export const doFlush = () => ({
  type: FLUSH
})

export type UIInputActionType = ReturnType<
  typeof doInput | typeof doClear | typeof doFlush
>

export interface UIInputStateType {
  inputs: {
    [key: string]: string | number
  }
}

export const initialInputState: UIInputStateType = {
  inputs: {}
}

export const inputs: Reducer<UIInputStateType, UIInputActionType> = (
  state = initialInputState,
  action
) => {
  switch (action.type) {
    case INPUT: {
      return {
        inputs: updateInputs(state.inputs, action.key, action.value)
      }
    }
    case CLEAR: {
      return {
        inputs: clearInputs(state.inputs, action.key)
      }
    }
    case FLUSH: {
      return {
        inputs: {}
      }
    }
    default: {
      return state
    }
  }
}

export const updateInputs = (
  inputs: { [key: string]: string | number },
  key: string,
  value: string | number
): { [key: string]: string | number } => {
  inputs[key] = value
  return {
    ...inputs
  }
}

export const clearInputs = (
  inputs: { [key: string]: string | number },
  key: string
): { [key: string]: string | number } => {
  inputs[key] = ''
  return {
    ...inputs
  }
}
