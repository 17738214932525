import styled from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  textAlign,
  TextAlignProps
} from 'styled-system'

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>

export interface BoxProps
  extends React.HTMLAttributes<HTMLDivElement>,
    SpaceProps,
    LayoutProps,
    TextAlignProps,
    Omit<ColorProps, 'color'> {}

export const Box = styled.div<BoxProps>`
  ${space}
  ${layout}
  ${textAlign}
  ${color}
`

export default Box
