import { Action } from '~/modules/core/actions'
import {
  AuthErrorResponseType,
  AuthRefreshType,
  AuthRequestType,
  AuthResponseType
} from '~/types/auth'

import AuthActionTypes from './actionTypes'

/** ログインリクエストを発行 */
export const requestLogin = (
  username: string,
  password: string
): Action<AuthRequestType> => ({
  type: AuthActionTypes.REQUEST_LOGIN,
  payload: {
    username,
    password
  }
})

/** ログイン成功 */
export const successLogin = (
  payload: AuthResponseType
): Action<AuthResponseType> => ({
  type: AuthActionTypes.SUCCESS_LOGIN,
  payload
})

/** ログイン失敗 */
export const failureLogin = (
  payload: AuthErrorResponseType
): Action<AuthErrorResponseType> => ({
  type: AuthActionTypes.FAILURE_LOGIN,
  payload
})

/** ログアウトリクエスト */
// 多分クッキーとかから消すだけ
export const requestLogout = (): Action<{}> => ({
  type: AuthActionTypes.REQUEST_LOGOUT,
  payload: {}
})

/** ログアウト成功 */
export const successLogout = (): Action<{}> => ({
  type: AuthActionTypes.SUCCESS_LOGOUT,
  payload: {}
})

/** ログアウト失敗 */
export const failureLogout = (): Action<{}> => ({
  type: AuthActionTypes.FAILURE_LOGOUT,
  payload: {}
})

export const requestRefresh = (token: string): Action<AuthRefreshType> => ({
  type: AuthActionTypes.REQUEST_REFRESH,
  payload: {
    token
  }
})

export const successRefresh = (
  payload: AuthResponseType
): Action<AuthResponseType> => ({
  type: AuthActionTypes.SUCCESS_REFRESH,
  payload
})

export const failureRefresh = (
  payload: AuthErrorResponseType
): Action<AuthErrorResponseType> => ({
  type: AuthActionTypes.FAILURE_REFRESH,
  payload
})

export type AuthActions = ReturnType<
  | typeof requestLogin
  | typeof successLogin
  | typeof failureLogin
  | typeof requestLogout
  | typeof successLogout
  | typeof failureLogout
  | typeof requestRefresh
  | typeof successRefresh
  | typeof failureRefresh
>

export default {
  requestLogin,
  successLogin,
  failureLogin,
  requestLogout,
  successLogout,
  failureLogout,
  requestRefresh,
  successRefresh,
  failureRefresh
}
