import { DSS3Response, DSSRequest } from '~/types/case'

import { Action, RequestPayload, SuccessPayload } from '../core/actions'
import DSS3Type from './actionTypes'

export type DSS3RequestInput = {
  q: string[]
}
export type DSS3SearhRequest = {} & RequestPayload<DSSRequest>
export type DSS3SearchSuccess = {} & SuccessPayload<DSS3Response>
export type DSS3SearchFailure = {}

export const requestDSS3 = (
  payload: DSS3RequestInput
): Action<DSS3SearhRequest> => ({
  type: DSS3Type.REQUEST_SEARCH,
  payload: {
    body: {
      q: payload.q.join(' ')
    }
  }
})

export const successDSS3 = (
  payload: DSS3SearchSuccess
): Action<DSS3SearchSuccess> => ({
  type: DSS3Type.SUCCESS_SEARCH,
  payload
})

export const failureDSS3 = (
  payload: DSS3SearchFailure
): Action<DSS3SearchFailure> => ({
  type: DSS3Type.FAILURE_SEARCH,
  payload
})

export type DSS3SearchActionType = ReturnType<
  typeof requestDSS3 | typeof successDSS3 | typeof failureDSS3
>

export const dss3Actions = {
  requestDSS3,
  successDSS3,
  failureDSS3
}
