import { call, put } from 'redux-saga/effects'

import {
  AuthRefreshType,
  AuthRequestType,
  AuthResponseType
} from '~/types/auth'

import AuthService from '../../service/auth'
import { Action } from '../core/actions'
import { readPolicyActions } from '../policy/actions'
import { readTermsActions } from '../terms/actions'
import authActions from './actions'

/**
 * 認証する
 */
export function* tryAuth(args: Action<AuthRequestType>) {
  // @ts-expect-error: TS7057: 'yield' expression implicitly results in an 'any'
  const response = yield AuthService.auth(args.payload)

  if (response.error) {
    yield put(authActions.failureLogin(response))
  } else {
    yield put(authActions.successLogin(response))
    setCookie(response)
    setStorage(response)
    const ids = getTermsPolicyIDS()
    if (ids.termsID) {
      yield put(readTermsActions.requestReadTerms(Number(ids.termsID)))
    }
    if (ids.policyID) {
      yield put(readPolicyActions.requestReadPolicy(Number(ids.policyID)))
    }
  }
}

// とりあえず
const setCookie = (response: AuthResponseType) => {
  if (!response.token) {
    return
  }
  const age = 30 * 60 * 60 * 24
  document.cookie = `token=${response.token}; max-age=${age}`
}

export function* leave() {
  yield call(() => {
    document.cookie = 'token=; max-age=0'
    location.href = process.env.PUBLIC_URL + '/'
  })
}

// とりあえず
const setStorage = (response: AuthResponseType) => {
  if (!response.user) {
    return
  }
  const { user } = response
  localStorage.setItem('permission', JSON.stringify(user.allPermissions))
}

/** refresh */
export function* doRefresh(args: Action<AuthRefreshType>) {
  // @ts-expect-error: TS7057: 'yield' expression implicitly results in an 'any'
  const response = yield AuthService.refresh(args.payload)

  if (response.error) {
    yield put(authActions.failureLogin(response))
  } else {
    yield put(authActions.successLogin(response))
    setCookie(response)
    setStorage(response)
  }
}

function getTermsPolicyIDS() {
  const termsID = localStorage.getItem('terms_id')
  const policyID = localStorage.getItem('policy_id')
  return {
    termsID,
    policyID
  }
}
