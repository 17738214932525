import { Reducer } from 'redux'

import { SearchHistory } from '~/types/suggest'

import { SaveHistoryPayload, SearchHistoryActions } from './actions'
import SearchHistoryType from './actionTypes'
import { getSearchHistory, setSearchHistory } from './selectors'

export type SearchHihstoryStateType = {
  keywords: SearchHistory[]
}

const initialSearchHistoryState: SearchHihstoryStateType = {
  keywords: []
}

/**
 * 症例
 */
export const searchHistory: Reducer<
  SearchHihstoryStateType,
  SearchHistoryActions
> = (state = initialSearchHistoryState, action) => {
  switch (action.type) {
    case SearchHistoryType.SAVE_SEARCH_HISTORY: {
      const payload = action.payload as SaveHistoryPayload
      return {
        ...state,
        ...setSearchHistory(payload.q)
      }
    }
    case SearchHistoryType.READ_SEARCH_HISTORY: {
      return {
        ...state,
        ...getSearchHistory()
      }
    }
    case SearchHistoryType.FLUSH_SEARCH_HISTORY: {
      return {
        ...state,
        keywrods: []
      }
    }
    default: {
      return state
    }
  }
}
