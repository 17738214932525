import { SuggestResponse } from '~/types/suggest'

import { SuccessPayload } from '../core/actions'

/**
 * レスポンスから適宜取り出して返す
 **/
export const getSuggestResult = (
  payload: SuccessPayload<SuggestResponse>
): {} => {
  const { count, candidates } = payload.data
  return {
    count,
    candidates
  }
}
