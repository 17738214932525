export const REQUEST_LOGIN = 'request_login' as const
export const SUCCESS_LOGIN = 'success_login' as const
export const FAILURE_LOGIN = 'failure_login' as const

export const REQUEST_LOGOUT = 'request_logout' as const
export const SUCCESS_LOGOUT = 'success_logout' as const
export const FAILURE_LOGOUT = 'failure_logout' as const

export const REQUEST_REFRESH = 'request_refresh' as const
export const SUCCESS_REFRESH = 'success_refresh' as const
export const FAILURE_REFRESH = 'failure_refresh' as const

export default {
  REQUEST_LOGIN,
  SUCCESS_LOGIN,
  FAILURE_LOGIN,
  REQUEST_LOGOUT,
  SUCCESS_LOGOUT,
  FAILURE_LOGOUT,
  REQUEST_REFRESH,
  SUCCESS_REFRESH,
  FAILURE_REFRESH
}
