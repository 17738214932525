import { RequestBase } from '~/types/network'

export type APIContext = {
  input: RequestInfo
  init?: RequestInit
}

export type APIContextBase = {
  input: string
  token: string | null
  noRequireToken?: boolean
  optionalHeader: (header: Headers) => Headers
  init?: {
    body?: RequestBase
    method?: string
  }
}

export type APIContextSender = {
  input: string
  optionalHeader: (header: Headers) => Headers
  init?: {
    body?: BodyInit
    method?: string
  }
}

type QueryMember = string | number | boolean | object

type QueryDict = {
  [key: string]: QueryMember | QueryMember[]
}

// object -> query string
const querySerialize = (
  obj: QueryDict,
  prefix: string | null = null
): string => {
  const str = []
  let p
  for (p in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, p)) {
      const k = prefix ? `${prefix}` : p,
        v = obj[p]

      str.push(
        v !== null && typeof v === 'object'
          ? // 複数を %20にする: exp: 発熱%20発疹
            // DSS4 だけ?
            v instanceof Array
            ? `${encodeURIComponent(k)}=${encodeURIComponent(v.join(' '))}`
            : querySerialize(v as QueryDict, k)
          : `${encodeURIComponent(k)}=${encodeURIComponent(v)}`
      )
    }
  }
  return str.join('&')
}

const contextParser = (context: APIContextBase): APIContextSender => {
  const { input, init, optionalHeader } = context
  let nextInput = `${input.toString()}?format=json`
  const nextInit: {
    body?: string
    method?: string
  } = {
    method: init && init.method ? init.method : undefined
  }
  if (init?.method && init.method === 'GET' && init.body) {
    nextInput = `${nextInput}&${querySerialize(
      JSON.parse(JSON.stringify(init.body))
    )}`
  } else if (init?.body) {
    nextInit.body = JSON.stringify(init.body)
  }

  return {
    optionalHeader,
    input: nextInput,
    init: nextInit
  }
}

/**
 * APIClient
 * @return Promise<Rseponse>
 */
const APIClient = (context: APIContextBase): Promise<Response> => {
  const { token, noRequireToken } = context

  // token なし
  if (!token && !noRequireToken) {
    document.cookie = 'token=; max-age=0'
    location.href = process.env.PUBLIC_URL + '/'
    return Promise.reject({ error: true, detail: context.input })
  }
  const { input, init, optionalHeader } = contextParser(context)

  const headers = optionalHeader(new Headers())
  headers.append('Content-Type', 'application/json')
  if (!noRequireToken) {
    headers.append('Authorization', `JWT ${token}`)
  }

  const reqInit = Object.assign({}, init, {
    headers,
    mode: 'cors',
    credentials: 'include'
  })

  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fetch(input as any, reqInit as any)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((req: any) => {
        // token refresh ? reauth ?
        if (req.status === 401) {
          document.cookie = 'token=; max-age=0'
          location.href = process.env.PUBLIC_URL + '/'
          return Promise.reject('Authorization required')
        }
        if (!req.ok) {
          return Promise.reject({ error: true, status: req.status })
        }
        return req.json()
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((err: any) => err)
  )
}

export default APIClient
