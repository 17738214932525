import {
  DSS1DiseaseRequest,
  DSS1DiseaseResponse,
  DSS1Response,
  DSS1V2Response,
  DSSDisease,
  DSSQuery,
  DSSV2Disease
} from '~/types/case'

import { RequestPayload, SuccessPayload } from '../core/actions'
import { DSS1DiseaseFailurePayload } from './actions'
import { DSS1DiseaseDataType } from './reducers'

/**
 * レスポンスから適宜取り出して返す
 **/
export const getSearchResultV1 = (
  payload: SuccessPayload<DSS1Response>
): {
  diseases: DSSDisease[] | null
  queries: DSSQuery[] | null
  count: number
} => {
  const { data } = payload
  const { diseases, queries, count } = data
  return {
    diseases,
    queries,
    count
  }
}

/**
 * レスポンスから適宜取り出して返す
 */
export const getSearchResult = (
  payload: SuccessPayload<DSS1V2Response>
): {
  diseases: DSSV2Disease[] | null
  queries: DSSQuery[] | null
  count: number
} => {
  const { data } = payload
  const { diseases, queries, count } = data
  diseases.forEach((disease) => {
    disease.version = 'v2'
  })
  return {
    diseases,
    queries,
    count
  }
}

// ------------------
// disease
// ------------------

export const setDiseaseLoadingState = (
  entries: DSS1DiseaseDataType[],
  payload: RequestPayload<DSS1DiseaseRequest>
): DSS1DiseaseDataType[] => {
  const key = payload.body.id
  const prevIndex = entries.findIndex((entry) => entry.key === key)
  // すでにある
  if (prevIndex > -1) {
    const next = Object.assign({}, entries[prevIndex])
    next.isLoading = true
    entries[prevIndex] = next
    return entries
  }
  entries.push({
    key,
    isLoading: true,
    data: null,
    hasError: false,
    wasLoaded: false
  })
  return entries
}

/** story のデータをセット */
export const setDiseaseData = (
  currentData: DSS1DiseaseDataType[],
  payload: SuccessPayload<DSS1DiseaseResponse>
): DSS1DiseaseDataType[] => {
  const { data } = payload

  const key = String(data.id)
  const prevIndex = currentData.findIndex((entry) => entry.key === key)
  if (prevIndex > -1) {
    const next = Object.assign({}, currentData[prevIndex])
    next.isLoading = false
    next.data = data
    next.wasLoaded = true
    next.hasError = false
    currentData[prevIndex] = next
    return currentData
  }
  currentData.push({
    key,
    data,
    wasLoaded: true,
    hasError: false,
    isLoading: false
  })
  return currentData
}

/** エラーを更新 */
export const setDiseaseError = (
  entries: DSS1DiseaseDataType[],
  payload: DSS1DiseaseFailurePayload
): DSS1DiseaseDataType[] => {
  const { key } = payload
  const nextIndex = entries.findIndex((item) => item.key === key)
  // 存在しない
  if (nextIndex < 0) {
    return entries
  }
  const next = Object.assign({}, entries[nextIndex])
  next.hasError = true
  next.wasLoaded = true
  next.isLoading = false
  entries[nextIndex] = next
  return entries
}
