import {
  DSS2DiseaseRequest,
  DSS2DiseaseResponse,
  DSS2Response,
  DSS2V2Response,
  DSSRequest
} from '~/types/case'

import { Action, RequestPayload, SuccessPayload } from '../core/actions'
import DSS2Type from './actionTypes'

// TODO ぱらめーたーふやす
export type DSS2RequestInput = {
  q: string[]
}
export type DSS2SearchRequest = {} & RequestPayload<DSSRequest>
export type DSS2V1SearchSuccess = {} & SuccessPayload<DSS2Response>
export type DSS2V1SearchFailure = {}

export const requestDSS2V1 = (
  payload: DSS2RequestInput
): Action<DSS2SearchRequest> => ({
  type: DSS2Type.REQUEST_SEARCH_V1,
  payload: {
    body: {
      q: payload.q.join(' ')
    }
  }
})

export const successDSS2V1 = (
  payload: DSS2V1SearchSuccess
): Action<DSS2V1SearchSuccess> => ({
  type: DSS2Type.SUCCESS_SEARCH_V1,
  payload
})

export const failureDSS2V1 = (
  payload: DSS2V1SearchFailure
): Action<DSS2V1SearchFailure> => ({
  type: DSS2Type.FAILURE_SEARCH_V1,
  payload
})

export type DSS2V1SearchActionType = ReturnType<
  typeof requestDSS2V1 | typeof successDSS2V1 | typeof failureDSS2V1
>

export const dss2V1Actions = {
  requestDSS2V1,
  successDSS2V1,
  failureDSS2V1
}

// --------------------------
// V2
// --------------------------

export type DSS2V2SearchSuccess = {} & SuccessPayload<DSS2V2Response>

export const requestDSS2 = (
  payload: DSS2RequestInput
): Action<DSS2SearchRequest> => ({
  type: DSS2Type.REQUEST_SEARCH,
  payload: {
    body: {
      q: payload.q.join(' ')
    }
  }
})

export const successDSS2 = (
  payload: DSS2V1SearchSuccess
): Action<DSS2V1SearchSuccess> => ({
  type: DSS2Type.SUCCESS_SEARCH,
  payload
})

export const failureDSS2 = (
  payload: DSS2V1SearchFailure
): Action<DSS2V1SearchFailure> => ({
  type: DSS2Type.FAILURE_SEARCH,
  payload
})

export type DSS2SearchActionType = ReturnType<
  typeof requestDSS2 | typeof successDSS2 | typeof failureDSS2
>

export const dss2Actions = {
  requestDSS2,
  successDSS2,
  failureDSS2
}

// ---------------------------
// DSS2 disease
// ---------------------------

export type DSS2DiseaseRequestInput = {
  q: string[]
  id: string
}

export type DSS2DiseaseRequestPayload = {} & RequestPayload<DSS2DiseaseRequest>
export type DSS2DiseaseSuccessPayload = {} & SuccessPayload<DSS2DiseaseResponse>
export type DSS2DiseaseFailurePayload = {
  key: string
}

export const requestDSS2Disease = (
  payload: DSS2DiseaseRequestInput
): Action<DSS2DiseaseRequestPayload> => ({
  type: DSS2Type.REQUEST_DISEASE,
  payload: {
    body: {
      q: payload.q.join(' '),
      id: payload.id
    }
  }
})

export const successDSS2Disease = (
  payload: DSS2DiseaseSuccessPayload
): Action<DSS2DiseaseSuccessPayload> => ({
  type: DSS2Type.SUCCESS_DISEASE,
  payload
})

export const failureDSS2Disease = (
  payload: DSS2DiseaseFailurePayload
): Action<DSS2DiseaseFailurePayload> => ({
  type: DSS2Type.FAILURE_DISEASE,
  payload
})

export type DSS2DiseaseActionType = ReturnType<
  | typeof requestDSS2Disease
  | typeof successDSS2Disease
  | typeof failureDSS2Disease
>

export const dss2DiseaseActions = {
  requestDSS2Disease,
  successDSS2Disease,
  failureDSS2Disease
}
