import { applyMiddleware, compose, createStore, Store } from 'redux'
import createSagaMiddleware, { END } from 'redux-saga'

import rootReducer from './reducer'
import rootSaga from './saga'

export interface SagaStore extends Store {
  // redux-saga がもう少し型付頑張ってくれるまでは。
  // eslint-disable-next-line
  runSaga?: any
  // eslint-disable-next-line
  close?: any
}

export default (initialState: object): SagaStore => {
  const ReduxSagaMiddleware = createSagaMiddleware()
  const store: SagaStore = createStore(
    rootReducer,
    initialState,
    compose(
      applyMiddleware(ReduxSagaMiddleware),
      process.env.NODE_ENV === 'development' &&
        typeof window === 'object' &&
        // ここはキャストしておかないと 非ブラウザ環境で死ぬ
        // eslint-disable-next-line
        typeof (window as any)['__REDUX_DEVTOOLS_EXTENSION__'] !== 'undefined'
        ? // eslint-disable-next-line
          (window as any)['__REDUX_DEVTOOLS_EXTENSION__']()
        : // eslint-disable-next-line
          (f: any) => f
    )
  )
  store.runSaga = ReduxSagaMiddleware.run
  store.runSaga(rootSaga)
  store.close = (): END => store.dispatch(END)

  return store
}
