import { Reducer } from 'redux'

import {
  DSS1DiseaseResponse,
  DSS1Response,
  DSS1V2Response,
  DSSDisease,
  DSSQuery,
  DSSV2Disease
} from '~/types/case'

import { SuccessPayload } from '../core/actions'
import { RequestableState } from '../core/reducers'
import {
  DSS1DiseaseActionType,
  DSS1DiseaseFailurePayload,
  DSS1DiseaseRequestPayload,
  DSS1SearchActionType,
  DSS1V1SearchActionType
} from './actions'
import DSS1Types from './actionTypes'
import {
  getSearchResult,
  getSearchResultV1,
  setDiseaseData,
  setDiseaseError,
  setDiseaseLoadingState
} from './selectors'

export type DSS1V1StateType = {
  diseases: DSSDisease[] | null
  queries: DSSQuery[] | null
  count: number
} & RequestableState

const initialDSS1V1State = {
  isLoading: false,
  diseases: null,
  queries: null,
  count: NaN,
  hasError: false,
  wasLoaded: false
}

/**
 * 症例
 */
export const dss1V1: Reducer<DSS1V1StateType, DSS1V1SearchActionType> = (
  state = initialDSS1V1State,
  action
) => {
  switch (action.type) {
    case DSS1Types.REQUEST_SEARCH_V1: {
      return {
        ...state,
        isLoading: true
      }
    }
    case DSS1Types.SUCCESS_SEARCH_V1: {
      const payload = action.payload as SuccessPayload<DSS1Response>

      return {
        ...state,
        ...getSearchResultV1(payload),
        isLoading: false,
        hasError: false,
        wasLoaded: true
      }
    }
    case DSS1Types.FAILURE_SEARCH_V1: {
      return {
        ...state,
        isLoading: false,
        hasError: true,
        wasLoaded: true
      }
    }
    default: {
      return state
    }
  }
}

export type DSS1StateType = {
  diseases: DSSV2Disease[] | null
  queries: DSSQuery[] | null
  count: number
} & RequestableState

const initialDSS1State = {
  isLoading: false,
  diseases: null,
  queries: null,
  count: NaN,
  hasError: false,
  wasLoaded: false
}

export const dss1: Reducer<DSS1StateType, DSS1SearchActionType> = (
  state = initialDSS1State,
  action
) => {
  switch (action.type) {
    case DSS1Types.REQUEST_SEARCH: {
      return {
        ...state,
        isLoading: true
      }
    }
    case DSS1Types.SUCCESS_SEARCH: {
      const payload = action.payload as SuccessPayload<DSS1V2Response>

      return {
        ...state,
        ...getSearchResult(payload),
        isLoading: false,
        hasError: false,
        wasLoaded: true
      }
    }
    case DSS1Types.FAILURE_SEARCH: {
      return {
        ...state,
        isLoading: false,
        hasError: true,
        wasLoaded: true
      }
    }
    default: {
      return state
    }
  }
}

export type DSS1DiseaseDataType = {
  key: string
  hasError: boolean
  wasLoaded: boolean
  isLoading: boolean
  data: DSS1DiseaseResponse | null
}

export type DSS1DiseaseStateType = {
  entries: DSS1DiseaseDataType[]
}

const initialDSS1DiseaseState: DSS1DiseaseStateType = {
  entries: []
}

export const dss1Disease: Reducer<
  DSS1DiseaseStateType,
  DSS1DiseaseActionType
> = (state = initialDSS1DiseaseState, action) => {
  switch (action.type) {
    case DSS1Types.REQUEST_DISEASE: {
      return {
        ...state,
        entries: setDiseaseLoadingState(
          state.entries,
          action.payload as DSS1DiseaseRequestPayload
        )
      }
    }
    case DSS1Types.SUCCESS_DISEASE: {
      const payload = action.payload as SuccessPayload<DSS1DiseaseResponse>

      return {
        ...state,
        entries: setDiseaseData(state.entries, payload)
      }
    }
    case DSS1Types.FAILURE_DISEASE: {
      const payload = action.payload as DSS1DiseaseFailurePayload
      return {
        ...state,
        entries: setDiseaseError(
          state.entries,
          payload as DSS1DiseaseFailurePayload
        )
      }
    }
    default: {
      return state
    }
  }
}
