import CircularProgress from '@material-ui/core/CircularProgress'
import React from 'react'
import styled from 'styled-components'

interface FrameProps {
  fullpage?: boolean
}

const Frame = styled.div<FrameProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props: FrameProps) => (props.fullpage ? '100vw' : '100%')};
  height: ${(props: FrameProps) => (props.fullpage ? '100vh' : '100%')};
  flex: 1;
  padding: 16px;
`

export interface LoadingIndicatorProps {
  fullpage?: boolean
  size?: number
}

export const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({
  fullpage,
  size = 40
}: LoadingIndicatorProps) => {
  return (
    <Frame fullpage={fullpage}>
      <CircularProgress size={size} />
    </Frame>
  )
}

export default LoadingIndicator
