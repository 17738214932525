import { put } from 'redux-saga/effects'

// import { FetchQueue } from '~/service/api/queue'
import { DSSRequest } from '~/types/case'
import { RequestContext } from '~/types/network'

import APIService from '../../service/api'
import { Action, RequestPayload } from '../core/actions'
import { getToken } from '../core/saga'
import { dss3Actions } from './actions'

/**
 * カテゴリ3取得
 */
export function* getDSS3(args: Action<RequestPayload<DSSRequest>>) {
  // @ts-expect-error: TS7057: 'yield' expression implicitly results in an 'any'
  const token = yield getToken()
  const req: RequestContext<DSSRequest> = {
    body: args.payload.body,
    token
  }

  let response
  // リクエスト実行
  // TODO あとで書きなおす
  try {
    // @ts-expect-error: TS7057: 'yield' expression implicitly results in an 'any'
    response = yield APIService['searchCat3'](req)
    // response = yield FetchQueue(APIService['searchCat3'], req)
  } catch (error) {
    response = error
  }

  if (response.error) {
    yield put(dss3Actions.failureDSS3({}))
  } else {
    yield put(dss3Actions.successDSS3({ data: response }))
  }
}
