import { Reducer } from 'redux'

import {
  DSS4DiseaseData,
  DSS4DiseaseResponse,
  DSS4StoryData,
  DSS4StoryRequest,
  DSS4StoryResponse
} from '~/types/case'

import { RequestPayload, SuccessPayload } from '../core/actions'
import { RequestableState } from '../core/reducers'
import {
  DSS4DiseaseActionType,
  DSS4StoryActionType,
  DSS4StoryFailure,
  ScoreUpdateType
} from './actions'
import DSS4Types from './actionTypes'
import {
  findUpdateScore,
  getSearchResult,
  setStoryData,
  setStoryError,
  setStoryLoadingState
} from './selectors'

export type DSS4DiseaseStateType = {
  data: DSS4DiseaseData | null // model作って model のがいい?
} & RequestableState

const initialDiseaseState = {
  isLoading: false,
  data: null,
  hasError: false,
  wasLoaded: false
}

/**
 * 症例
 */
export const dss4Disease: Reducer<
  DSS4DiseaseStateType,
  DSS4DiseaseActionType
> = (state = initialDiseaseState, action) => {
  switch (action.type) {
    case DSS4Types.REQUEST_DISEASE: {
      return {
        ...state,
        isLoading: true
      }
    }
    case DSS4Types.SUCCESS_DISEASE: {
      const payload = action.payload as SuccessPayload<DSS4DiseaseResponse>
      return {
        ...state,
        data: getSearchResult(payload),
        isLoading: false,
        hasError: false,
        wasLoaded: true
      }
    }
    case DSS4Types.UPDATE_SCORE: {
      const updater = action.payload as ScoreUpdateType
      return {
        ...state,
        data:
          state.data && findUpdateScore(state.data as DSS4DiseaseData, updater),
        isLoading: false,
        hasError: false,
        wasLoaded: true
      }
    }
    case DSS4Types.FAILURE_DISEASE: {
      return {
        ...state,
        isLoading: false,
        hasError: true,
        wasLoaded: true
      }
    }
    default: {
      return state
    }
  }
}

export type DSS4StoryStateType = {
  entries: DSS4StoryStateDataType[]
}

export type DSS4StoryStateDataType = {
  key: string
  data: DSS4StoryData | null
  isLoading: boolean
  hasError: boolean
  wasLoaded: boolean
}

const initialStoryState: DSS4StoryStateType = {
  entries: []
}

/**
 * ストーリー
 */
export const dss4Story: Reducer<DSS4StoryStateType, DSS4StoryActionType> = (
  state = initialStoryState,
  action
) => {
  switch (action.type) {
    case DSS4Types.REQUEST_STORY: {
      return {
        ...state,
        entries: setStoryLoadingState(
          state.entries,
          action.payload as RequestPayload<DSS4StoryRequest>
        )
      }
    }
    case DSS4Types.SUCCESS_STORY: {
      const payload = action.payload as DSS4StoryResponse
      return {
        ...state,
        entries: setStoryData(state.entries, payload)
      }
    }
    case DSS4Types.FAILURE_STORY: {
      const payload = action.payload
      return {
        ...state,
        entries: setStoryError(state.entries, payload as DSS4StoryFailure)
      }
    }
    default: {
      return state
    }
  }
}

export const dss4AllStory: Reducer<
  DSS4StoryStateType,
  DSS4DiseaseActionType
> = (state = initialStoryState, action) => {
  switch (action.type) {
    case DSS4Types.SUCCESS_DISEASE: {
      const payload = action.payload as SuccessPayload<DSS4DiseaseResponse>
      const { data } = payload
      if (!data.result || !data.data) {
        return state
      }
      let entries = state.entries
      for (const d of data.data.diseases) {
        const storyResponse: DSS4StoryResponse = {
          result: true,
          errorCode: '',
          errorMessage: '',
          data: { ...d }
        }
        entries = setStoryData(entries, storyResponse)
        delete d.list
      }
      return {
        ...state,
        entries: entries
      }
    }
    default: {
      return state
    }
  }
}
