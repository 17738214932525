import { Reducer } from 'redux'

import { AuthErrorResponseType, AuthResponseType } from '~/types/auth'

import { RequestableState } from '../core/reducers'
import { AuthActions } from './actions'
import AuthActionTypes from './actionTypes'

export type AuthStateType = {
  token: string | null
  nonFieldErrors: string[]
  email: string
  password: string
  username: string
  hasReadLatestTos: boolean
  hasReadLatestPp: boolean
} & RequestableState

const initialAuthState: AuthStateType = {
  isLoading: false,
  token: null,
  nonFieldErrors: [],
  hasReadLatestTos: false,
  hasReadLatestPp: false,
  email: '',
  password: '',
  username: '',
  wasLoaded: false,
  hasError: false
}

/**
 * ログイン
 */
export const auth: Reducer<AuthStateType, AuthActions> = (
  state = initialAuthState,
  action
) => {
  switch (action.type) {
    case AuthActionTypes.REQUEST_LOGIN: {
      return {
        ...state,
        isLoading: true
      }
    }
    case AuthActionTypes.SUCCESS_LOGIN: {
      const payload = action.payload as AuthResponseType
      const token = payload.token
      return {
        ...state,
        isLoading: false,
        token,
        nonFieldErrors: [],
        username: payload.user.username,
        email: payload.user.email,
        // password: payload.user.password,
        hasReadLatestPp: payload.user.hasReadLatestPp,
        hasReadLatestTos: payload.user.hasReadLatestTos,
        hasError: false,
        wasLoaded: true
      }
    }
    case AuthActionTypes.FAILURE_LOGIN: {
      const { nonFieldErrors, username } =
        action.payload as AuthErrorResponseType
      return {
        ...state,
        isLoading: false,
        nonFieldErrors,
        username,
        // password,
        hasError: true,
        wasLoaded: true
      }
    }
    default: {
      return state
    }
  }
}
