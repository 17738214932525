import { RequestContext } from '~/types/network'

export type ChanStateType = 'wait' | 'running' | 'finish' | 'failed' | 'cancel'

// 何本まで並列にアクセスすることを許可するか
// 完成したらこんふぃぐに持っていく
const threshold = 120

type Chan = {
  state: ChanStateType
  job: (chan: Chan) => Promise<unknown>
  args: RequestContext<{}>
}

type QueueProps = {}

const Queue = (
  job: (request: RequestContext<{}>) => Promise<Response>,
  args: RequestContext<{}>
) =>
  new Promise((resolve, reject) => {
    const chan: Chan = {
      state: 'wait',
      job: async (chan) => {
        // だめだった処理は 渡される job に一任する
        const res = await job(args)
        try {
          chan.state = 'finish'
          resolve(res)
          cycle()
        } catch (error) {
          chan.state = 'failed'
          reject(res)
          cycle()
        }
      },
      args
    }
    waitListAdd(chan)
  })

export const FetchQueue = Queue

const wait: Chan[] = []
let running: Chan[] = []

const waitListAdd = (chan: Chan) => {
  wait.push(chan)
  exec()
}

const exec = () => {
  //実行中が規定数に達している
  if (running.length >= threshold) {
    return
  }
  const next = wait.shift()
  if (!next) {
    return
  }

  running.push(next)
  // もしそれでもしんどくて更に絞りたければこのあたりに sleep() 仕込む
  cycle()
}

const cycle = () => {
  // 稼働中がない
  if (running.length < 1) {
    return
  }
  const removeIndexes: number[] = []
  running.forEach((chan, index) => {
    if (chan.state === 'wait') {
      chan.state = 'running'
      chan.job(chan)
    }
    if (chan.state === 'failed' || chan.state === 'finish') {
      // console.log('perge chan:', chan)
      removeIndexes.push(index)
    }
  })
  running = running.filter((_, index) => removeIndexes.indexOf(index) < 0)
  // console.log(
  //   'FetchQueue status: running:',
  //   running.length,
  //   'wait:',
  //   wait.length
  // )
  if (running.length > 0 && running.length < threshold) {
    exec()
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const sleep = (time: number) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(null)
    }, time)
  })
