import { combineReducers } from 'redux'

import { auth } from './auth/reducers'
import { cases } from './case/reducers'
import { dss1, /*dss1V1*/ dss1Disease } from './cat1/reducers'
import { dss2, dss2Disease } from './cat2/reducers'
import { dss3 } from './cat3/reducers'
import { dss4AllStory, dss4Disease, dss4Story } from './dss4/reducers'
import { policy } from './policy/reducers'
import { searchHistory } from './searchHistory/reducers'
import { suggest } from './suggest/reducers'
import { summary } from './summary/reducers'
import { terms } from './terms/reducers'

const reducer = combineReducers({
  auth,
  suggest,
  // dss1V1,
  dss1,
  dss1Disease,
  dss2,
  dss2Disease,
  dss3,
  // 日立システム
  dss4Disease: dss4Disease,
  // 日立システム
  dss4Story: dss4Story,
  // 日立システム
  dss4AllStory: dss4AllStory,
  summary,
  searchHistory,
  cases,
  terms,
  policy
})

export type RootState = ReturnType<typeof reducer>

export default reducer
