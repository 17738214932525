import Bundler from '~/bundler'
import { PathParams } from '~/bundler/default'
import { RequestBase, RequestContext, RequestPathParams } from '~/types/network'

import { APIServiceProps } from '.'
import APIClient, { APIContextBase } from './client'

type DUMMY = {}

const dummyRes: Response = new Response()
const dummyTmp: {
  [key: string]: (request: RequestContext<DUMMY>) => Promise<Response>
} = {}
const methods = Bundler.http.endpoints.reduce((prev, current) => {
  prev[current.name] = (): Promise<Response> => {
    return Promise.resolve(dummyRes)
  }
  return prev
}, dummyTmp)

export type BundledMethods = typeof methods

const protocoler = (path: string): string => {
  const protocol = window.location.protocol === 'http:' ? 'http:' : 'https:'
  path = path.replace('{host}', window.location.host)
  if (path.startsWith('http')) {
    return path
  } else {
    return `${protocol}//${path}`
  }
}

export const checkParamRequire = (
  pathParams: RequestPathParams | undefined,
  orgPathParams: PathParams[]
): RequestPathParams => {
  if (!pathParams) {
    pathParams = {}
  }
  orgPathParams.forEach((param) => {
    // デフォルトが設定されていて実行時に指定されていない
    if (
      param.default &&
      !(param.paramName in (pathParams as RequestPathParams))
    ) {
      ;(pathParams as RequestPathParams)[param.paramName] = param.default
    }
    // require じゃないものはいらん
    if (!param.require) {
      return
    }
    // リクエストされてない
    if (!(param.paramName in (pathParams as RequestPathParams))) {
      throw new Error('require params')
    }
  })
  return pathParams
}

/**
 * リクエストパスを組み立てる
 */
export const generatePath = (
  request: RequestContext<RequestBase>,
  base: string,
  defaultVersion: string,
  orgPathParams?: PathParams[]
): string => {
  // pathParams を使ってないパターン
  if (!orgPathParams || orgPathParams.length < 1) {
    base = base.replace('{version}', defaultVersion)
    return protocoler(base)
  }

  let { pathParams } = request
  pathParams = checkParamRequire(pathParams, orgPathParams)
  if (pathParams) {
    if (!('version' in pathParams)) {
      pathParams['version'] = defaultVersion
    }

    const result = Object.keys(pathParams).reduce(
      (prev, current) =>
        prev.replace(
          `{${current}}`,
          String((pathParams as RequestPathParams)[current])
        ),
      base
    )
    return protocoler(result)
  }
  return protocoler(base)
}

const APIBundler = (service: APIServiceProps) => {
  const { basePath, http, defaultVersion } = Bundler

  const tmp: {
    [key: string]: (request: RequestContext<RequestBase>) => Promise<Response>
  } = {}

  // 環境ごとのヘッダーをセットする
  const { optionalHeader } = http

  http.endpoints.forEach((endpoint) => {
    const { name, method, path, pathParams } = endpoint
    const url = basePath + path

    // メソッドのバインド
    tmp[name] = (request: RequestContext<RequestBase>): Promise<Response> => {
      const context: APIContextBase = {
        input: generatePath(request, url, defaultVersion, pathParams),
        token: request.token,
        noRequireToken: request.noRequiredToken,
        optionalHeader,
        init: {
          method,
          body: request.body
        }
      }

      return APIClient(context)
    }
  })
  // バインド
  Object.assign(service, tmp)
}

export default APIBundler
