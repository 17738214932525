import React, { useEffect } from 'react'
import ReactMarkdown from 'react-markdown'
import { useDispatch, useSelector } from 'react-redux'

import AnyDialog from '~/components/molecules/AnyDialog'
import { requestPolicy, requestReadPolicy } from '~/modules/policy/actions'
import { RootState } from '~/modules/reducer'

export interface PrivacyPolicyDialogProps {
  open: boolean
  onClose: () => void
}

const PrivacyPolicyDialog: React.FC<PrivacyPolicyDialogProps> = ({
  open,
  onClose
}: PrivacyPolicyDialogProps) => {
  const dispatch = useDispatch()
  const { body, wasRequested, wasLoaded, id } = useSelector(
    (state: RootState) => state.policy
  )
  const { token, hasReadLatestPp } = useSelector(
    (state: RootState) => state.auth
  )

  useEffect(() => {
    if (!wasRequested && !wasLoaded) {
      dispatch(requestPolicy())
    }
    // ログイン中に規約が書き換わった時
    if (wasLoaded && token && !hasReadLatestPp) {
      dispatch(requestReadPolicy(id))
    }
  }, [wasLoaded, wasRequested])

  return (
    <AnyDialog
      onClose={onClose}
      open={open}
      title="プライバシーポリシー"
      maxWidth="md"
    >
      {/* <ReactMarkdown>privacyPolicy</ReactMarkdown> */}
      <ReactMarkdown>{body}</ReactMarkdown>
    </AnyDialog>
  )
}

export default PrivacyPolicyDialog
