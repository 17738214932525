export const APP_NAME = `診断困難例ケースサーチ`
export const APP_NAME_ALT = `J-CaseMap`
export const DISEASES_UNIT = `疾患`
export const CASES_UNIT = `症例`
export const CATEGORY_ALL_TITLE = `すべて`
export const CATEGORY_1_TITLE = `カテゴリー1`
export const CATEGORY_1_DESCRIPTION = `単一疾患（病態）で説明できる\n単一疾患の複数の症例報告をもとに検索`
export const CATEGORY_2_TITLE = `カテゴリー2`
export const CATEGORY_2_DESCRIPTION = `単一疾患（病態）で説明できる\n単一疾患の単一の症例報告をもとに検索`
export const CATEGORY_3_TITLE = `カテゴリー3`
export const CATEGORY_3_DESCRIPTION = `複数の疾患（病態）で説明できる\n合併症をもつ単一の症例報告をもとに検索`
export const CATEGORY_4_TITLE = `カテゴリー4`
export const CATEGORY_4_DESCRIPTION = `複数の疾患（病態）で説明できる\n関連する病態を組み合わせた推測`
export const CATEGORY_4_DESCRIPTION_ALT = `以下は複数の症例の論理を結合した推測です。\n必ずしも現実の報告症例ではありませんので、参考としてご覧ください。`
export const CATEGORY_DESCRIPTION_SEPARATOR = `： `
export const DISEASE_NOT_FOUND = `該当する${DISEASES_UNIT}が見つかりませんでした`
export const CASE_NOT_FOUND = `該当する${CASES_UNIT}が見つかりませんでした`
export const PAGE_NOT_FOUND = `ページが見つかりませんでした`
export const ACCOUNT_LABEL = `アカウント`
export const LOGOUT_LABEL = `ログアウト`
export const NUM_MATCHED_CASES_LABEL = `検索語を含む症例数`
export const NUM_REGISTERED_CASES_LABEL = `登録されている症例数`
export const SEARCH_WORLD_LABEL = `検索語`
export const DESCRIBED_CAUSE_LABEL = `原因として記載された症例`
export const DESCRIBED_RESULT_LABEL = `結果として記載された症例`
export const NUM_CASES_LABEL = `症例数`
export const NUM_HITS_LABEL = `症例数`
export const RESPONSE_TIME_LABEL = `計算時間`
export const ITEMS_UNIT = `件`
export const STORIES_UNIT = `ストーリー`

export const DISEASE_LIST_SLICE = 100
