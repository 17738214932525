import { createTheme } from '@material-ui/core'
import blue from '@material-ui/core/colors/blue'
import green from '@material-ui/core/colors/green'
import {
  StylesProvider,
  ThemeProvider as MaterialThemeProvider
} from '@material-ui/styles'
import React from 'react'
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components'
import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
    background-color: #ebeff2;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;
  }

  *, *::before, *::after {
      box-sizing: inherit;
  }

  body {
    height: 100%;
    margin: 0;
    font-family: 'Noto Sans JP', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    font-feature-settings: "palt" 1;
    -webkit-tap-highlight-color: transparent;

    &::backdrop {
      background-color: #fff;
    }
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  #root {
    height: 100%;
  }
`

export const theme = createTheme({
  typography: {
    fontFamily: "'Noto Sans JP', sans-serif",
    button: {
      textTransform: 'none'
    }
  },
  palette: {
    primary: blue,
    secondary: green
  }
})

export const styledComponentsTheme = Object.assign(Object.assign({}, theme), {
  breakpoints: Object.values(theme.breakpoints.values).slice(1)
})

interface ThemeProviderProps {
  children: React.ReactNode
}

const ThemeProvider = ({ children }: ThemeProviderProps) => {
  return (
    <>
      <GlobalStyle />
      <StylesProvider injectFirst>
        <MaterialThemeProvider theme={theme}>
          <StyledComponentsThemeProvider theme={styledComponentsTheme}>
            {children}
          </StyledComponentsThemeProvider>
        </MaterialThemeProvider>
      </StylesProvider>
    </>
  )
}

export default ThemeProvider
