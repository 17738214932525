import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { grey } from '@material-ui/core/colors'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import {
  alpha,
  createStyles,
  makeStyles,
  Theme
} from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import HistoryIcon from '@material-ui/icons/History'
import SearchIcon from '@material-ui/icons/Search'
import Autocomplete, {
  AutocompleteRenderInputParams
} from '@material-ui/lab/Autocomplete'
import _ from 'lodash'
import { nextTick } from 'process'
import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  // useMemo,
  useRef,
  useState
} from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router'
import { useInput } from 'rooks'
import styled from 'styled-components'

import Config from '~/config/'
import { UIStore } from '~/context'
import { doInput } from '~/context/input'
import { RootState } from '~/modules/reducer'
import { requestSuggest } from '~/modules/suggest/actions'
import { SearchHistory } from '~/types/suggest'
import { keywordSortDescending, safeDecode, safeEncode } from '~/utils/search'

export type SuggestProps = {
  placeholderText?: string
}

const Frame = styled(Paper)`
  display: flex;
  flex-direction: row;
  flex: 1;
`
const Row = styled.div`
  flex: 1;
`

const ButtonRow = styled.div`
  padding: 2px;
`

const OptionItemIcon = styled.div`
  color: rgba(0, 0, 0, 0.54);
  display: inline-flex;
  flex-shrink: 0;
  min-width: 42px;
  padding-left: 4px;
`

const OptionItemLabel = styled.div`
  flex: 1 1 auto;
  min-width: 0;
`

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25)
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      minWidth: 200,
      border: 'none',
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: 'auto'
      }
    },
    searchIcon: {
      width: theme.spacing(7),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    inputRoot: {
      color: 'inherit',
      // padding: theme.spacing(1, 1, 1, 7),
      transition: theme.transitions.create('width'),
      width: '100%'
    },
    inputInput: {
      width: '100%',
      backgroundColor: 'white'
    },
    inputInputNotchedOutline: {
      border: 'none'
    }
  })
)

/**
 * サジェスト反応の対象外とするキーコード
 */
const checkIgnoreKey = (keyName: string): boolean => {
  return (
    // 足りなければ足す
    ['meta', 'control', 'escape', 'capslock', 'tab', 'shift'].indexOf(keyName) >
    -1
  )
}

const SearchIconFrame = styled.div`
  margin: -12px 0;
  color: ${grey[700]};
`

interface SuggestType {
  label: string
  value: string
  isHistory?: boolean
}

const SearchInput: FC<SuggestProps> = ({
  placeholderText = '検索語を入力...'
}: SuggestProps) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { query, categoryNumber } = useParams()
  const { uiState, uiDispatch } = useContext(UIStore)
  const [lastWord, setLastWord] = useState('')
  const [intervalBeacon, setBeacon] = useState<number | undefined>(undefined)
  const inputRef = useRef()
  const [open, setOpen] = useState(false)
  const [canSubmit, setCanSubmit] = useState(false)
  const [isLastKeyArrow, setIsLastKeyArrow] = useState(false)

  /** サジェストへのリクエストを実行する */
  const doSuggestRequest = (word: string) => {
    // 空だったときはリクエストを行わない
    if (word === '') {
      return
    }

    // eslint-disable-next-line no-irregular-whitespace
    // const lastWord = word.split(/(\s|　)/).slice(-1)[0]

    // if (lastWord) {
    //   dispatch(requestSuggest({ q: lastWord }))
    // }
    dispatch(requestSuggest({ q: word, limit: 50 }))
  }

  const searchInput = useInput(query || '')

  // query を変える
  const updateQuery = (qString: string) => {
    const path = `/${safeEncode(qString)}/${
      categoryNumber ? `category${categoryNumber}/` : ''
    }`
    // console.log('location push at search input', path)
    navigate(path)
  }

  // TODO fix function name
  const update = (value: string) => {
    setLastWord(value)
    if (value !== '') {
      doSuggestRequest(value)
    }
  }

  const canSubmitHandler = () => {
    setCanSubmit(true)
  }

  // キーアップ
  const keyUpHandler = useCallback(
    (event) => {
      const key = event.key.toLowerCase()
      const ignoreCode = checkIgnoreKey(key)
      if (key == 'arrowdown' || key == 'arrowup') {
        setIsLastKeyArrow(true)
      } else if (key != 'enter') {
        setIsLastKeyArrow(false)
      }
      // 受け付けいないキーコードであれば何もせず返す
      if (ignoreCode) {
        return
      }
      clearTimeout(intervalBeacon)
      // とりあえずエンター
      // TODO 日本語中
      if (canSubmit && key === 'enter') {
        const value = event.target.value
        // query 更新を止める
        updateQuery(value)
        setOpen(false)
        // event.target.blur()
        return
      }
      // インターバル中でも一定以上のタイプ数だった場合はサジェストリクエスト
      if (
        event.target.value.length - lastWord.length >
        Config.ui.SUGGEST_WORD_THRESHOLD
      ) {
        const value = event.target.value
        update(value)
        return
      }
      setCanSubmit(false)
      // インターバルを設ける
      // NOTICE: TypeScript では window.setTimeout と setTimeout は別物なので。
      const beacon = window.setTimeout(() => {
        const input = inputRef.current
        if (input) {
          const value = (input as HTMLInputElement).value
          update(value)
        }
      }, Config.ui.SUGGEST_INTERVAL)
      setBeacon(beacon)
    },
    [
      intervalBeacon,
      inputRef,
      setLastWord,
      lastWord,
      searchInput.value,
      canSubmit
    ]
  )

  const handleSearch = useCallback(() => {
    const input = inputRef.current
    if (input) {
      const value = (input as HTMLInputElement).value
      updateQuery(value)
      setOpen(false)
      focusInput()
    }
  }, [])

  const changeHandler = useCallback(
    (event, value) => {
      const isHistory = event.target.getAttribute('data-is-history')
      // 履歴からのアクセス
      if (value && isHistory) {
        updateQuery(value.value)
        return
      }
    },
    [uiState.inputs.inputs]
  )

  const classes = useStyles()

  const [options, setOptions] = useState<SuggestType[]>([])

  const { candidates, isLoading, keywords } = useSelector(
    (state: RootState) => ({
      ...state.suggest,
      ...state.searchHistory
    }),
    (left, right) => _.isEqual(left, right)
  )

  keywordSortDescending(keywords)

  const keywordDep = keywords ? keywords.join('') : null
  const candidatesDep = candidates ? candidates.join('') : null

  useEffect(() => {
    const keywordOptions: SuggestType[] = keywords.map<SuggestType>(
      (keyword: SearchHistory) => ({
        label: safeDecode(keyword.query),
        value: safeDecode(keyword.query),
        isHistory: true
      })
    )

    const candidateOptions: SuggestType[] =
      candidates &&
      candidates.map<SuggestType>((candidate: string) => ({
        label: candidate,
        value: candidate
      }))
    const options: SuggestType[] = candidateOptions
      .concat(keywordOptions)
      .filter((item) => !!item)
    setOptions([])
    nextTick(() => {
      setOptions(options)
    })
  }, [keywordDep, candidatesDep])

  const autocompleteProps = {
    value: {
      label: safeDecode(`${uiState.inputs.inputs['myValue'] || ''}`),
      value: safeDecode(`${uiState.inputs.inputs['myValue'] || ''}`)
    }
  }

  const focusInput = () => {
    if (inputRef.current) {
      const input = inputRef.current as HTMLInputElement
      const value = input.value
      input.value = value
      nextTick(() => {
        input.focus()
      })
    }
  }
  const inputChangeHandler = useCallback(
    (event, value) => {
      if (event && event.type == 'keydown' && !isLastKeyArrow) {
        return
      }
      // updateValue(value)
      uiDispatch(doInput('myValue', value))
      focusInput()
    },
    [isLastKeyArrow]
  )

  const RenderInput: FC<AutocompleteRenderInputParams> = (params) => (
    <TextField
      {...params}
      fullWidth
      className={classes.search}
      onChange={searchInput.onChange}
      onKeyUp={keyUpHandler}
      onKeyPress={canSubmitHandler}
      variant="outlined"
      placeholder={placeholderText}
      size="small"
      classes={{
        root: classes.inputRoot
      }}
      InputProps={{
        ...params.InputProps,
        inputRef: inputRef,
        classes: {
          root: classes.inputInput,
          notchedOutline: classes.inputInputNotchedOutline
        },
        startAdornment: (
          <SearchIconFrame>
            <IconButton onClick={handleSearch}>
              <SearchIcon />
            </IconButton>
            {params.InputProps.startAdornment}
          </SearchIconFrame>
        ),
        endAdornment: (
          <>
            {isLoading ? <CircularProgress color="primary" size={20} /> : null}
            {params.InputProps.endAdornment}
          </>
        )
      }}
    />
  )

  const openHandler = () => {
    setOpen(true)
  }

  const closeHandler = () => {
    setOptions([])
    nextTick(() => {
      setOpen(false)
    })
  }

  const RenderOption = (option: SuggestType) => {
    const aOption = option as SuggestType
    // const label = useMemo(() => (option ? safeDecode(option.label) : ''), [
    //   option
    // ])
    return (
      <>
        <OptionItemIcon>{aOption.isHistory && <HistoryIcon />}</OptionItemIcon>
        <OptionItemLabel data-is-history={aOption.isHistory}>
          {safeDecode(option.label)}
        </OptionItemLabel>
      </>
    )
  }

  return (
    <Frame>
      <Row>
        <Autocomplete
          autoComplete={false}
          autoHighlight={false}
          includeInputInList={true}
          freeSolo={true}
          autoSelect={false}
          open={open}
          onOpen={openHandler}
          onClose={closeHandler}
          getOptionSelected={(option, value) => option.value === value.value}
          getOptionLabel={(option) => option.label as string}
          options={options}
          onChange={changeHandler}
          onInputChange={inputChangeHandler}
          {...autocompleteProps}
          renderInput={RenderInput}
          filterSelectedOptions={true}
          noOptionsText={'...'}
          renderOption={RenderOption}
          PopperComponent={(props) => (
            <Popper
              popperOptions={{
                modifiers: {
                  preventOverflow: { enabled: false },
                  hide: { enabled: false },
                  flip: { enabled: false }
                }
              }}
              {...props}
            />
          )}
        />
      </Row>
      <ButtonRow>
        <Button
          onClick={handleSearch}
          variant="contained"
          color="primary"
          disableElevation
        >
          検索
        </Button>
      </ButtonRow>
    </Frame>
  )
}

export default SearchInput
