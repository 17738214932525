import { SummaryResponse, WordSummary } from '~/types/summary'

import { SuccessPayload } from '../core/actions'

/**
 * レスポンスから適宜取り出して返す
 **/
export const getSummaryResult = (
  payload: SuccessPayload<SummaryResponse>
): {
  queries: WordSummary[]
} => {
  const { data } = payload
  const { queries } = data
  return {
    queries
  }
}
