import { AuthRefreshType, AuthRequestType } from '~/types/auth'

import AuthClient from './client'

/** 認証を実行するサービス */
const AuthService = {
  /* ログインを実行します */
  auth: (payload: AuthRequestType) => {
    const body = JSON.stringify(payload)
    return AuthClient({ body })
  },
  refresh: (payload: AuthRefreshType) => {
    const body = JSON.stringify(payload)
    return AuthClient({ body, wantRefresh: true })
  },
  /** ログアウトを実行します */
  logout: () => ({})
}
export default AuthService
