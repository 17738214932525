import { Reducer } from 'redux'

import { SuggestResponse } from '~/types/suggest'

import { SuccessPayload } from '../core/actions'
import { RequestableState } from '../core/reducers'
import { SuggestActionType } from './actions'
import SuggestTypes from './actionTypes'
import { getSuggestResult } from './selectors'

export type SuggestStateType = {
  count: number
  candidates: string[]
} & RequestableState

const initialSuggestState = {
  isLoading: false,
  count: 0,
  candidates: [],
  hasError: false,
  // あまり意味がない
  wasLoaded: false
}

/**
 * 症例
 */
export const suggest: Reducer<SuggestStateType, SuggestActionType> = (
  state = initialSuggestState,
  action
) => {
  switch (action.type) {
    case SuggestTypes.REQUEST_SUGGEST: {
      return {
        ...state,
        isLoading: true
      }
    }
    case SuggestTypes.SUCCESS_SUGGEST: {
      const payload = action.payload as SuccessPayload<SuggestResponse>

      return {
        ...state,
        ...getSuggestResult(payload),
        isLoading: false,
        hasError: false,
        wasLoaded: true
      }
    }
    case SuggestTypes.FAILURE_SUGGEST: {
      return {
        ...state,
        isLoading: false,
        hasError: true,
        wasLoaded: true
      }
    }
    default: {
      return state
    }
  }
}
