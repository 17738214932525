import { Reducer } from 'redux'

import { TermsResponse } from '~/types/terms'

import { SuccessPayload } from '../core/actions'
import { RequestableState } from '../core/reducers'
import { TermsReadRequestActionType, TermsRequestActionType } from './actions'
import TermsType from './actionTypes'
// import { getSearchResult } from './selectors'

export type TermsStateType = {
  id: number
  body: string
  created: string
  wasRequested: boolean
} & RequestableState

const initialTermsState = {
  isLoading: false,
  id: NaN,
  body: '',
  created: '',
  hasError: false,
  wasLoaded: false,
  wasRequested: false
}

/**
 * 規約
 */
export const terms: Reducer<TermsStateType, TermsRequestActionType> = (
  state = initialTermsState,
  action
) => {
  switch (action.type) {
    case TermsType.REQUEST_TERMS: {
      return {
        ...state,
        isLoading: true,
        wasRequested: true
      }
    }
    case TermsType.SUCCESS_TERMS: {
      const payload = action.payload as SuccessPayload<TermsResponse>

      return {
        ...state,
        ...payload.data,
        isLoading: false,
        hasError: false,
        wasLoaded: true
      }
    }
    case TermsType.FAILURE_TERMS: {
      return {
        ...state,
        isLoading: false,
        hasError: true,
        wasLoaded: true
      }
    }
    default: {
      return state
    }
  }
}

export type ReadTermsStateType = {} & RequestableState

const initialReadTermsState = {
  isLoading: false,

  hasError: false,
  wasLoaded: false
}

export const readTerms: Reducer<
  ReadTermsStateType,
  TermsReadRequestActionType
> = (state = initialReadTermsState, action) => {
  switch (action.type) {
    case TermsType.REQUEST_READ_TERMS: {
      return {
        ...state,
        isLoading: true
      }
    }
    case TermsType.SUCCESS_READ_TERMS: {
      // const payload = action.payload as SuccessPayload<{}>

      return {
        ...state,
        isLoading: false,
        hasError: false,
        wasLoaded: true
      }
    }
    case TermsType.FAILURE_READ_TERMS: {
      return {
        ...state,
        isLoading: false,
        hasError: true,
        wasLoaded: true
      }
    }
    default: {
      return state
    }
  }
}
