import { put } from 'redux-saga/effects'

import { FetchQueue } from '~/service/api/queue'
import { RequestContext } from '~/types/network'
import { TermsRequest, TermsResponse } from '~/types/terms'

import APIService from '../../service/api'
import { Action, RequestPayload } from '../core/actions'
import { getToken } from '../core/saga'
import { readTermsActions, termsActions } from './actions'

/**
 * 利用規約取得
 */
export function* getTerms() {
  // @ts-expect-error: TS7057: 'yield' expression implicitly results in an 'any'
  const token = yield getToken()
  const req: RequestContext<TermsRequest> = {
    token,
    noRequiredToken: true
  }
  let response
  // リクエスト実行
  try {
    // @ts-expect-error: TS7057: 'yield' expression implicitly results in an 'any'
    response = yield FetchQueue(APIService['getTerms'], req)
  } catch (error) {
    response = error
  }

  if (response.error) {
    yield put(termsActions.failureTerms({}))
  } else {
    yield put(termsActions.successTerms({ data: response }))
    setStorage(response)
  }
}

/**
 * 利用規約既読
 */
export function* readTerms(args: Action<RequestPayload<{ id: number }>>) {
  // @ts-expect-error: TS7057: 'yield' expression implicitly results in an 'any'
  const token = yield getToken()
  const req: RequestContext<{}> = {
    token,
    pathParams: {
      id: args.payload.body.id
    }
  }
  let response
  // リクエスト実行
  try {
    // @ts-expect-error: TS7057: 'yield' expression implicitly results in an 'any'
    response = yield FetchQueue(APIService['readTerms'], req)
  } catch (error) {
    response = error
  }

  if (response.error) {
    yield put(readTermsActions.failureReadTerms({}))
  } else {
    yield put(readTermsActions.successReadTerms({ data: response }))
  }
}

const setStorage = (response: TermsResponse) => {
  if (!response.body) {
    return
  }
  const { id } = response
  localStorage.setItem('terms_id', String(id))
}
