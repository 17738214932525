import { put } from 'redux-saga/effects'

// import { FetchQueue } from '~/service/api/queue'
import { RequestContext } from '~/types/network'
import { SummaryRequest } from '~/types/summary'

import APIService from '../../service/api'
import { Action, RequestPayload } from '../core/actions'
import { getToken } from '../core/saga'
import { summaryActions } from './actions'

/**
 * サマリー取得
 */
export function* getSummary(args: Action<RequestPayload<SummaryRequest>>) {
  // @ts-expect-error: TS7057: 'yield' expression implicitly results in an 'any'
  const token = yield getToken()

  const req: RequestContext<SummaryRequest> = {
    body: args.payload.body,
    token
  }
  let response
  // リクエスト実行
  // TODO 後で書き直す
  try {
    // @ts-expect-error: TS7057: 'yield' expression implicitly results in an 'any'
    response = yield APIService['summary'](req)
    // response = yield FetchQueue(APIService['summary'], req)
  } catch (error) {
    response = error
  }

  if (response.error) {
    yield put(summaryActions.failureSummary({}))
  } else {
    yield put(summaryActions.successSummary({ data: response }))
  }
}
