/* eslint no-irregular-whitespace: ["off"] */

import React from 'react'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import styled from 'styled-components'

import AnyDialog from '~/components/molecules/AnyDialog'

const about = `
### 更新情報（2024年9月1日 Ver.5）
 - 症例は20,268症例まで増やしました。
 - 症例が増えても検索速度が低下しないようにアルゴリズムを改善しました。
 - 検索語を入力した後に、リターンを使うことができるようにしました。

　J-CaseMapは、日常の臨床経験から内科学を構築することを目的として、日本内科学会地方会の症例報告を整理したデータベースをもとに作成されました。自治医科大学地域医療センター総合診療部門（松村正巳部門長）の医師をはじめとする約150名の内科医が中心となり、症例報告文の論理を図式化しました。現在も作業は続いており、今回のシステムはそのなかで、研究代表者永井良三（自治医科大学）が修正および用語整理した2万268例を利用しました。また検索に必要な辞書（約4万2千語の代表語とそれぞれの同義語・検索拡張語）は永井が作成しました。これは確定的診断を行うものではなく、鑑別診断の参考となる疾患や病態をリマインドしていただくためのシステムです。まだ開発段階ですが、会員の皆様に活用いただくために公開しました。<br />
　検索ソフトは小田啓太（元Googleエンジニア、自治医科大学）、粟飯原俊介(自治医科大学)、今井健（東京大学、自治医科大学）、佐藤寿彦（プレシジョン社、医師）が分担して開発し、検索アルゴリズムの一部は特許登録されています（特許6539818）。<br />
　開発にあたっては、平成28年度AMED臨床研究等ICT基盤構築研究事業「人工知能による総合診療診断支援システムの開発」（研究代表者:永井良三）」、令和元年度NEDO Connected Industries 推進のための協調領域データ共有・AI システム開発促進事業「医療情報を横断的に統合した診療支援 AI システムの開発」（研究代表者:佐藤寿彦）の支援を受けました。<br />
　なお今回の追加症例のうち2081例は、国立情報学研究所で開発中の汎用型日本語大規模言語モデルLLM-jp 13Bを用いて図式化の下書きをしました。このAI技術は令和5-9年度内閣府第3期戦略的イノベーション創造プログラム「統合的ヘルスケアシステムの構築」（PD:永井良三）の採択課題「大規模医療文書・画像の高精度解析基盤技術の開発」（研究代表者黒橋禎夫京都大学特定教授／国立情報学研究所長）によるものです。<br />
`

const collaborators = `
研究体制

- 自治医科大学
  - 永井良三（研究代表者）
  - 地域医療学センター
    - 松村正巳　小谷和彦　村松慎一　亀崎豊実　畠山修司　梶井英治*
    - 中村剛史　竹島太郎　古城隆雄　神崎　綱　寺裏寛之
  - 内科学講座循環器内科学部門
    - 苅尾七臣　甲谷友幸
  - 内科学講座呼吸器内科学部門
    - 萩原弘一
  - 内科学講座腎臓内科学部門
    - 長田太助　菅生太朗　大原　健
  - 内科学講座アレルギー膠原病学部門
    - 長嶋孝夫
  - 総合医学第１講座循環器科
    - 藤田英雄
  - 薬理学講座
    - 今井　靖　相澤健一　今井　利美
  - 病理学講座
    - 仲矢丈雄　辻賢太郎
  - データサイエンスセンター
    - 興梠貴英　今井　健　小田啓太　粟飯原俊介　笹渕裕介
- 東京大学大学院医学系研究科疾患生命工学センター
    - 今井　健
- 東京大学内科学専攻循環器内科学
    - 武田憲彦　武田憲文　藤生克仁
- 山形大学内科学第一講座　
    - 渡辺昌文
- 大阪医科大学内科学第三講座
    - 石坂信和*
- 那賀町立上那賀病院
    - 林　宏樹
- 都立広尾病院
    - 小山　茂
- 聖路加国際病院
    - 水野　篤
- 東京クリニック
    - 稲葉祐介*
- 株式会社プレシジョン
    - 佐藤寿彦
- 国立情報学研究所
    - 黒橋禎夫　相澤彰子
- 京都大学情報学研究科
    - 黒橋禎夫　Cheng Fei　八幡早紀子

（＊平成28-29年当時の所属）
`

const CollaboratorsDocument = styled(ReactMarkdown)`
  line-height: 2;

  ul {
    list-style: none;
    padding: 0;
    ul {
      padding-left: 4rem;
    }
  }
`

export interface CollaboratorsDialogProps {
  open: boolean
  onClose: () => void
}

const CollaboratorsDialog: React.FC<CollaboratorsDialogProps> = ({
  open,
  onClose
}: CollaboratorsDialogProps) => {
  return (
    <AnyDialog
      onClose={onClose}
      open={open}
      title={'J-CaseMap とは'}
      maxWidth="md"
    >
      <ReactMarkdown rehypePlugins={[rehypeRaw]}>{about}</ReactMarkdown>
      <br />
      <CollaboratorsDocument className="" rehypePlugins={[rehypeRaw]}>
        {collaborators}
      </CollaboratorsDocument>
    </AnyDialog>
  )
}

export default CollaboratorsDialog
