import {
  all,
  AllEffect,
  ForkEffect,
  takeEvery,
  takeLatest
} from 'redux-saga/effects'

import AuthTypes from '~/modules/auth/actionTypes'
import { doRefresh, leave, tryAuth } from '~/modules/auth/saga'
import CaseTypes from '~/modules/case/actionTypes'
import { getCase } from '~/modules/case/saga'
import DSS1Types from '~/modules/cat1/actionTypes'
import { /* getDSS1 */ getDSS1, getDSS1Disease } from '~/modules/cat1/saga'
import DSS2Types from '~/modules/cat2/actionTypes'
import { getDSS2, getDSS2Disease } from '~/modules/cat2/saga'
import DSS3Types from '~/modules/cat3/actionTypes'
import { getDSS3 } from '~/modules/cat3/saga'
import DSS4Types from '~/modules/dss4/actionTypes'
import { getDisease, getStory } from '~/modules/dss4/saga'
import PolicyTypes from '~/modules/policy/actionTypes'
import { getPolicy, readPolicy } from '~/modules/policy/saga'
import SuggestTypes from '~/modules/suggest/actionTypes'
import { getSuggest } from '~/modules/suggest/saga'
import SummaryTypes from '~/modules/summary/actionTypes'
import { getSummary } from '~/modules/summary/saga'
import TermsType from '~/modules/terms/actionTypes'
import { getTerms, readTerms } from '~/modules/terms/saga'

export default function* rootSaga(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([
    takeEvery(AuthTypes.REQUEST_LOGIN, tryAuth),
    takeEvery(AuthTypes.REQUEST_LOGOUT, leave),
    takeEvery(AuthTypes.REQUEST_REFRESH, doRefresh),
    takeEvery(TermsType.REQUEST_TERMS, getTerms),
    takeEvery(PolicyTypes.REQUEST_POLICY, getPolicy),
    takeEvery(TermsType.REQUEST_READ_TERMS, readTerms),
    takeEvery(PolicyTypes.REQUEST_READ_POLICY, readPolicy),
    // require auth
    // takeLatest(DSS1Types.REQUEST_SEARCH, getDSS1),
    // v2
    takeLatest(DSS1Types.REQUEST_SEARCH, getDSS1),
    // v2
    takeLatest(DSS1Types.REQUEST_DISEASE, getDSS1Disease),
    takeLatest(DSS2Types.REQUEST_SEARCH, getDSS2),
    takeLatest(DSS2Types.REQUEST_DISEASE, getDSS2Disease),
    takeLatest(DSS3Types.REQUEST_SEARCH, getDSS3),
    takeLatest(DSS4Types.REQUEST_DISEASE, getDisease),
    takeLatest(DSS4Types.REQUEST_STORY, getStory),
    takeLatest(SuggestTypes.REQUEST_SUGGEST, getSuggest),
    takeLatest(SummaryTypes.REQUEST_SUMMARY, getSummary),
    takeLatest(CaseTypes.REQUEST_CASE, getCase)
  ])
}
