import Config from '~/config'

export type AuthContext = {
  body: string
  wantRefresh?: boolean
}

/**
 * AuthClient
 * @return Promise<Rseponse>
 */
const AuthClient = (context: AuthContext): Promise<Response> => {
  const protocol = window.location.protocol === 'http:' ? 'http:' : 'https:'
  const AUTH_HOST = Config.auth.AUTH_HOST.replace(
    '{host}',
    window.location.host
  )
  let input = ''
  if (AUTH_HOST.startsWith('https://')) {
    input = `${AUTH_HOST}${
      context.wantRefresh ? Config.auth.REFRESH_URL : Config.auth.AUTH_URL
    }`
  } else {
    input = `${protocol}${AUTH_HOST}${
      context.wantRefresh ? Config.auth.REFRESH_URL : Config.auth.AUTH_URL
    }`
  }

  const headers = new Headers()
  headers.append('Content-Type', 'application/json')
  // 仮
  const method = 'POST'
  const credentials: 'same-origin' | 'include' | 'omit' | undefined = 'include'
  const mode: 'same-origin' | 'cors' | 'navigate' | 'no-cors' | undefined =
    'cors'
  const reqInit = Object.assign({}, context, {
    headers,
    method,
    mode,
    credentials
  })
  return fetch(input, reqInit)
    .then(async (res) => {
      if (res.status !== 200) {
        const json = await res.json()
        return { error: true, ...json, status: res.status }
      }
      return res.json()
    })
    .catch((err) => ({
      error: true,
      err,
      nonFieldErrors: ['サーバーとの通信に失敗しました']
    }))
}

export default AuthClient
