import { Reducer } from 'redux'

import { CaseDetailResponse } from '~/types/case'

import {
  CaseActions,
  CaseDetailRequestInput,
  CaseFailurePayload,
  CaseSuccessPayload
} from './actions'
import CaseTypes from './actionTypes'
import {
  setCaseDetailData,
  setCaseDetailError,
  setCaseDetailLoading
} from './selectors'

// import { getSearchResult } from './selectors'

export type CaseStateType = {
  cases: CaseStateData[]
}

export type CaseStateData = {
  key: string
  data: CaseDetailResponse | null
  errorBody?: string
  isLoading: boolean
  wasLoaded: boolean
  hasError: boolean
}

const initialCaseState: CaseStateType = {
  cases: []
}

/**
 * 症例
 */
export const cases: Reducer<CaseStateType, CaseActions> = (
  state = initialCaseState,
  action
) => {
  switch (action.type) {
    case CaseTypes.REQUEST_CASE: {
      return {
        ...state,
        cases: setCaseDetailLoading(
          state.cases,
          action.payload as CaseDetailRequestInput
        )
      }
    }
    case CaseTypes.SUCCESS_CASE: {
      const payload = action.payload as CaseSuccessPayload
      return {
        ...state,
        cases: setCaseDetailData(state.cases, payload)
      }
    }
    case CaseTypes.FAILURE_CASE: {
      return {
        ...state,
        cases: setCaseDetailError(
          state.cases,
          action.payload as CaseFailurePayload
        )
      }
    }
    default: {
      return state
    }
  }
}
