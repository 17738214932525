import { put } from 'redux-saga/effects'

import APIService from '../../service/api'
import { Action, RequestPayload } from '../core/actions'
import { getToken } from '../core/saga'
import { caseActions } from './actions'
// import {} from '~/types/case'

/**
 * 症例本文取得
 */
export function* getCase(args: Action<RequestPayload<string>>) {
  // @ts-expect-error: TS7057: 'yield' expression implicitly results in an 'any'
  const token = yield getToken()

  const req = {
    pathParams: {
      name: args.payload.body
    },
    token
  }
  let response
  // リクエスト実行
  // TODO 後で書き直す
  try {
    // @ts-expect-error: TS7057: 'yield' expression implicitly results in an 'any'
    response = yield APIService['caseDetail'](req)
  } catch (error) {
    response = error
  }

  if (response.error || response.detail) {
    yield put(
      caseActions.failureCase({
        key: args.payload.body,
        error: response.detail ? response.detail : null
      })
    )
  } else {
    yield put(caseActions.successCase({ data: response }))
  }
}
