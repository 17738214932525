import { clone, merge } from 'lutils'

import defaultBundler from './default'

const Bundler = merge(clone(defaultBundler), {
  env: 'production',
  lastModified: '2020-01-06 16:07',
  basePath: `${location.hostname}/{version}`,
  http: {
    optionalHeader: (header: Headers): Headers => header
  }
})

export default Bundler
