import { TermsRequest, TermsResponse } from '~/types/terms'

import { Action, RequestPayload, SuccessPayload } from '../core/actions'
import TermsType from './actionTypes'

// import { DSSRequest, TermsResponse } from '~/types/case'

export type TermsRequestPayload = {} & RequestPayload<TermsRequest>
export type TermsSearchSuccess = {} & SuccessPayload<TermsResponse>
export type TermsSearchFailure = {}

export const requestTerms = (): Action<TermsRequest> => ({
  type: TermsType.REQUEST_TERMS,
  payload: {}
})

export const successTerms = (
  payload: TermsSearchSuccess
): Action<TermsSearchSuccess> => ({
  type: TermsType.SUCCESS_TERMS,
  payload
})

export const failureTerms = (
  payload: TermsSearchFailure
): Action<TermsSearchFailure> => ({
  type: TermsType.FAILURE_TERMS,
  payload
})

export type TermsRequestActionType = ReturnType<
  typeof requestTerms | typeof successTerms | typeof failureTerms
>

export const termsActions = {
  requestTerms,
  successTerms,
  failureTerms
}

export type TermsReadRequestPayload = {} & RequestPayload<{ id: number }>

export const requestReadTerms = (
  id: number
): Action<TermsReadRequestPayload> => ({
  type: TermsType.REQUEST_READ_TERMS,
  payload: {
    body: { id }
  }
})

export const successReadTerms = (
  payload: TermsSearchSuccess
): Action<TermsSearchSuccess> => ({
  type: TermsType.SUCCESS_READ_TERMS,
  payload
})

export const failureReadTerms = (
  payload: TermsSearchFailure
): Action<TermsSearchFailure> => ({
  type: TermsType.FAILURE_READ_TERMS,
  payload
})

export type TermsReadRequestActionType = ReturnType<
  typeof requestReadTerms | typeof successReadTerms | typeof failureReadTerms
>

export const readTermsActions = {
  requestReadTerms,
  successReadTerms,
  failureReadTerms
}
