import React from 'react'

import Box from '~/components/atoms/Box'
import { PAGE_NOT_FOUND } from '~/components/constants'
import AppLayout from '~/components/layouts/AppLayout'
import AppBar from '~/components/organisms/AppBar'

const NotFoundPage: React.FC = () => {
  return (
    <AppLayout testId="NotFoundPage" appBar={<AppBar />}>
      <Box p={4}>{PAGE_NOT_FOUND}</Box>
    </AppLayout>
  )
}

export default NotFoundPage
