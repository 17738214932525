import { Reducer } from 'redux'

import { SummaryResponse, WordSummary } from '~/types/summary'

import { SuccessPayload } from '../core/actions'
import { RequestableState } from '../core/reducers'
import { SummaryActionType } from './actions'
import SummaryTypes from './actionTypes'
import { getSummaryResult } from './selectors'

export type SummaryStateType = {
  queries: WordSummary[]
} & RequestableState

const initialSummaryState: SummaryStateType = {
  isLoading: false,
  queries: [],
  hasError: false,
  wasLoaded: false
}

/**
 * 症例
 */
export const summary: Reducer<SummaryStateType, SummaryActionType> = (
  state = initialSummaryState,
  action
) => {
  switch (action.type) {
    case SummaryTypes.REQUEST_SUMMARY: {
      return {
        ...state,
        isLoading: true
      }
    }
    case SummaryTypes.SUCCESS_SUMMARY: {
      const payload = action.payload as SuccessPayload<SummaryResponse>

      return {
        ...state,
        ...getSummaryResult(payload),
        isLoading: false,
        hasError: false,
        wasLoaded: true
      }
    }
    case SummaryTypes.FAILURE_SUMMARY: {
      return {
        ...state,
        isLoading: false,
        hasError: true,
        wasLoaded: true
      }
    }
    default: {
      return state
    }
  }
}
