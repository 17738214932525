import { clone, merge } from 'lutils'

import defaultConfig from './default'

const Config = merge(clone(defaultConfig), {
  config: 'production',
  lastModified: '2020-01-06 16:07',
  auth: {
    AUTH_HOST: `//${location.hostname}/v1`
  }
})

export default Config
