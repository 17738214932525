import { AppBar as MUIAppBar } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import AccountCircle from '@material-ui/icons/AccountCircle'
import DescriptionIcon from '@material-ui/icons/Description'
import ErrorIcon from '@material-ui/icons/Error'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import FindInPageIcon from '@material-ui/icons/FindInPage'
import FormatSizeIcon from '@material-ui/icons/FormatSize'
import HomeIcon from '@material-ui/icons/Home'
import LockIcon from '@material-ui/icons/Lock'
import MenuIcon from '@material-ui/icons/Menu'
import MoreIcon from '@material-ui/icons/MoreVert'
import PeopleIcon from '@material-ui/icons/People'
import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { APP_NAME, APP_NAME_ALT, LOGOUT_LABEL } from '~/components/constants'
import FontSizeChanger from '~/components/molecules/FontSizeChanger'
import SearchInput from '~/components/molecules/SearchInput'
import CollaboratorsDialog from '~/components/organisms/CollaboratorsDialog'
import PrivacyPolicyDialog from '~/components/organisms/PrivacyPolicyDialog'
import UsageDialog from '~/components/organisms/UsageDialog'
import { requestLogout } from '~/modules/auth/actions'
import { RootState } from '~/modules/reducer'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      position: 'relative',
      zIndex: theme.zIndex.appBar,
      flexGrow: 1
    },
    spacer: {
      width: theme.spacing(3)
    },
    maxSpacer: {
      flex: 1
    },
    menuButton: {
      marginLeft: -18,
      marginRight: 4
    },
    titleLine: {
      display: 'flex',
      alignItems: 'baseline',
      color: 'white',
      textDecoration: 'none'
    },
    title: {
      display: 'block'
    },
    titleAlt: {
      display: 'block',
      fontWeight: 500,
      fontSize: '1rem',
      paddingBottom: 2,
      marginLeft: theme.spacing(2)
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex'
      }
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none'
      }
    },
    topAppbar: {
      backgroundColor: 'transparent',
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: 'none'
    },
    drawerMenuList: {
      minWidth: 250
    }
  })
)

const MenuDivider = styled(Divider)`
  margin: 8px 0;
`

export interface AppBarProps {
  isTop?: boolean
}

export const AppBar: React.FC<AppBarProps> = ({ isTop }: AppBarProps) => {
  const classes = useStyles()

  const { wasLoaded, email, username } = useSelector(
    (state: RootState) => state.auth
  )

  const [isUsageDialogOpen, setUsageDialogOpen] = useState(false)
  const [isNotesDialogOpen, setNotesDialogOpen] = useState(false)

  const [isCollaboratorsDialogOpen, setCollaboratorsDialogOpenOpen] =
    useState(false)

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null)

  const dispatch = useDispatch()

  const location = useLocation()

  const isMenuOpen = Boolean(anchorEl)
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
    handleMobileMenuClose()
  }

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget)
  }

  const navigate = useNavigate()

  const [drawerOpen, setDrawerOpen] = useState(false)

  const handleLogout = useCallback(() => {
    dispatch(requestLogout())
  }, [dispatch])

  const menuId = 'primary-search-account-menu'
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {/* <MenuDivider /> */}
      <MenuItem onClick={handleLogout}>{LOGOUT_LABEL}</MenuItem>
    </Menu>
  )

  const mobileMenuId = 'primary-search-account-menu-mobile'
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>{username || email}</p>
      </MenuItem>
    </Menu>
  )

  return (
    <div className={classes.grow}>
      <MUIAppBar
        position="static"
        classes={{
          root: isTop ? classes.topAppbar : ''
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
            onClick={() => setDrawerOpen(true)}
          >
            <MenuIcon />
          </IconButton>
          {!isTop ? (
            <>
              <Hidden smDown>
                <Link to="/" className={classes.titleLine}>
                  <Typography className={classes.title} variant="h6" noWrap>
                    {APP_NAME}
                  </Typography>
                  <Typography className={classes.titleAlt} variant="h6" noWrap>
                    {APP_NAME_ALT}
                  </Typography>
                </Link>
              </Hidden>
              <Hidden smDown>
                <div className={classes.spacer} />
              </Hidden>
              <SearchInput />
              <Hidden smDown>
                <div className={classes.spacer} />
              </Hidden>
            </>
          ) : (
            <div className={classes.maxSpacer} />
          )}
          <div className={classes.sectionDesktop}>
            <FontSizeChanger>
              <IconButton aria-label="change font size" color="inherit">
                <FormatSizeIcon />
              </IconButton>
            </FontSizeChanger>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              edge="end"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </MUIAppBar>
      {renderMobileMenu}
      {renderMenu}
      <SwipeableDrawer
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        onOpen={() => setDrawerOpen(true)}
      >
        <div className={classes.drawerMenuList} role="presentation">
          <List>
            <ListItem
              button
              disabled={location.pathname === '/'}
              onClick={() => {
                navigate('/')
              }}
            >
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="トップページ" />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                setUsageDialogOpen(true)
              }}
            >
              <ListItemIcon>
                <FindInPageIcon />
              </ListItemIcon>
              <ListItemText primary="検索について" />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                setNotesDialogOpen(true)
              }}
            >
              <ListItemIcon>
                <ErrorIcon />
              </ListItemIcon>
              <ListItemText primary="注意事項" />
            </ListItem>
            {wasLoaded && <TermsAndPolicyMenuItem />}
            <ListItem
              button
              onClick={() => {
                setCollaboratorsDialogOpenOpen(true)
              }}
            >
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText primary="J-CaseMap とは" />
            </ListItem>
            <MenuDivider />
            <ListItem button onClick={handleLogout}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary={LOGOUT_LABEL} />
            </ListItem>
          </List>
        </div>
      </SwipeableDrawer>
      <UsageDialog
        open={isUsageDialogOpen}
        content="usage"
        onClose={() => {
          setUsageDialogOpen(false)
        }}
      />
      <UsageDialog
        open={isNotesDialogOpen}
        content="notes"
        onClose={() => {
          setNotesDialogOpen(false)
        }}
      />

      <CollaboratorsDialog
        open={isCollaboratorsDialogOpen}
        onClose={() => {
          setCollaboratorsDialogOpenOpen(false)
        }}
      />
    </div>
  )
}

export default AppBar

// FIXME: いどうしてください
const TermsAndPolicyMenuItem = () => {
  const { hasReadLatestPp, hasReadLatestTos } = useSelector(
    (state: RootState) => state.auth
  )
  const [isTermsDialogOpen, setTermsDialogOpen] = useState(!hasReadLatestTos)
  const [isPrivacyPolicyDialogOpen, setPrivacyPolicyDialogOpen] = useState(
    !hasReadLatestPp
  )

  return (
    <>
      <ListItem
        button
        onClick={() => {
          setTermsDialogOpen(true)
        }}
      >
        <ListItemIcon>
          <DescriptionIcon />
        </ListItemIcon>
        <ListItemText primary="利用規約" />
      </ListItem>
      <UsageDialog
        open={isTermsDialogOpen}
        content="terms"
        onClose={() => {
          setTermsDialogOpen(false)
        }}
      />
      <PrivacyPolicyDialog
        open={isPrivacyPolicyDialogOpen}
        onClose={() => {
          setPrivacyPolicyDialogOpen(false)
        }}
      />
    </>
  )
}
