import { SummaryRequest, SummaryResponse } from '~/types/summary'

import { Action, RequestPayload, SuccessPayload } from '../core/actions'
import SummaryType from './actionTypes'

// TODO ぱらめーたーふやす
export type SummaryRequestInput = {
  q: string[]
}
export type SummaryRequestPayload = {} & RequestPayload<SummaryRequest>
export type SummarySuccessPayload = {} & SuccessPayload<SummaryResponse>
export type SummaryFailurePayload = {}

export const requestSummary = (
  payload: SummaryRequestInput
): Action<SummaryRequestPayload> => ({
  type: SummaryType.REQUEST_SUMMARY,
  payload: {
    body: {
      q: payload.q.join(' ')
    }
  }
})

export const successSummary = (
  payload: SummarySuccessPayload
): Action<SummarySuccessPayload> => ({
  type: SummaryType.SUCCESS_SUMMARY,
  payload
})

export const failureSummary = (
  payload: SummaryFailurePayload
): Action<SummaryFailurePayload> => ({
  type: SummaryType.FAILURE_SUMMARY,
  payload
})

export type SummaryActionType = ReturnType<
  typeof requestSummary | typeof successSummary | typeof failureSummary
>

export const summaryActions = {
  requestSummary,
  successSummary,
  failureSummary
}
