import { SuggestRequest, SuggestResponse } from '~/types/suggest'
import { normalizeSearchWord } from '~/utils/search'

import { Action, RequestPayload, SuccessPayload } from '../core/actions'
import SuggestType from './actionTypes'

// TODO ぱらめーたーふやす
export type SuggestRequestInput = {
  q: string
  limit?: number
}
export type SuggestSearhRequest = {} & RequestPayload<SuggestRequest>
export type SuggestSuccess = {} & SuccessPayload<SuggestResponse>
export type SuggestFailure = {}

export const requestSuggest = (
  payload: SuggestRequestInput
): Action<SuggestSearhRequest> => ({
  type: SuggestType.REQUEST_SUGGEST,
  payload: {
    body: {
      q: normalizeSearchWord(payload.q),
      limit: payload.limit
    }
  }
})

export const successSuggest = (
  payload: SuggestSuccess
): Action<SuggestSuccess> => ({
  type: SuggestType.SUCCESS_SUGGEST,
  payload
})

export const failureSuggest = (
  payload: SuggestFailure
): Action<SuggestFailure> => ({
  type: SuggestType.FAILURE_SUGGEST,
  payload
})

export type SuggestActionType = ReturnType<
  typeof requestSuggest | typeof successSuggest | typeof failureSuggest
>

export const suggestActions = {
  requestSuggest,
  successSuggest,
  failureSuggest
}
