import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import CacheBuster, {
  CacheBusterState
} from '~/components/organisms/CacheBuster'
import ErrorBoundary from '~/components/organisms/ErrorBoundary'
import Config from '~/config'
import createStore from '~/modules/store'
import { APIServiceInit } from '~/service/api'
import ThemeProvider from '~/theme'

import App from './App'
import { UIProvider } from './context'
import { requestRefresh } from './modules/auth/actions'
import * as serviceWorker from './serviceWorker'

if (process.env.NODE_ENV === 'development') {
  // NOTE: https://github.com/welldone-software/why-did-you-render/issues/85
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const whyDidYouRender = require('@welldone-software/why-did-you-render')
  whyDidYouRender(React, {
    trackAllPureComponents: true,
    trackExtraHooks: [[require('react-redux'), 'useSelector']]
  })
}

// ちょっと仮
const tokenStr = document.cookie
  .split('; ')
  .find((c) => c.indexOf('token=') === 0)
let token: unknown
if (tokenStr) {
  token = tokenStr.split('=')[1]
} else if (Config.dummyToken) {
  token = '123456789'
}

// LocalStore とかから取るならここで入れる
export const store = createStore({
  auth: {
    token
  }
})

const startApp = () => {
  APIServiceInit()

  if (token !== undefined) {
    store.dispatch(requestRefresh(token as string))
  }

  ReactDOM.render(
    <ErrorBoundary>
      <CacheBuster>
        {({
          loading,
          isLatestVersion,
          refreshCacheAndReload
        }: CacheBusterState) => {
          if (loading) return null
          if (!loading && !isLatestVersion) {
            refreshCacheAndReload()
          }

          return (
            <UIProvider>
              <Provider store={store}>
                <ThemeProvider>
                  <App />
                </ThemeProvider>
              </Provider>
            </UIProvider>
          )
        }}
      </CacheBuster>
    </ErrorBoundary>,
    document.getElementById('root')
  )

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.register()
}

// eslint-disable-next-line
declare let window: any

if (window.cordova) {
  document.addEventListener('deviceready', startApp, false)
} else {
  startApp()
}
