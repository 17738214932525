import { SearchHistory } from '~/types/suggest'

export const normalizeSearchWord = (word: string) => {
  return encodeURIComponent(word)
}

export const queryToObj = (query: string): { [key: string]: string } => {
  const queries = query.split('?').filter((q) => q !== '')
  query = queries[0]
  const tmp: { [key: string]: string } = {}
  const obj = query
    .split('&')
    .map((kv: string) => kv.split('='))
    .reduce((prev, current) => {
      prev[current[0]] = current[1]
      return prev
    }, tmp)

  return obj
}

/** 検索履歴を新しい順にソート */
export const keywordSortDescending = (keywords: SearchHistory[]) => {
  keywords.sort((a, b) => {
    if (a.searchedAt > b.searchedAt) {
      return -1
    }
    if (a.searchedAt < b.searchedAt) {
      return 1
    }
    return 0
  })
}

export const safeEncode = (targetStr: string): string => {
  return encodeURIComponent(targetStr)
}

export const safeDecode = (encodedStr: string): string => {
  let decoded: string
  try {
    decoded = decodeURIComponent(encodedStr)
  } catch (error) {
    decoded = encodeURIComponent(encodedStr)
    decoded = decodeURIComponent(decoded)
  }
  return decoded
}
