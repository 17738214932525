export const REQUEST_SEARCH_V1 = 'request_cateogry2_v1_search' as const
export const SUCCESS_SEARCH_V1 = 'success_cateogry2_v1_search' as const
export const FAILURE_SEARCH_V1 = 'failure_cateogry2_search' as const

export const REQUEST_SEARCH = 'request_cateogry2_search' as const
export const SUCCESS_SEARCH = 'success_cateogry2_search' as const
export const FAILURE_SEARCH = 'failure_cateogry2_search' as const

export const REQUEST_DISEASE = 'request_cateogry2_disease' as const
export const SUCCESS_DISEASE = 'success_cateogry2_disease' as const
export const FAILURE_DISEASE = 'failure_cateogry2_disease' as const

export default {
  REQUEST_SEARCH_V1,
  SUCCESS_SEARCH_V1,
  FAILURE_SEARCH_V1,
  REQUEST_SEARCH,
  SUCCESS_SEARCH,
  FAILURE_SEARCH,
  REQUEST_DISEASE,
  SUCCESS_DISEASE,
  FAILURE_DISEASE
}
