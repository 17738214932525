import React from 'react'
// import bugsnag from '@bugsnag/js'
// import bugsnagReact from '@bugsnag/plugin-react'

// export const bugsnagClient = bugsnag('fc9d21a60e3dd8c61d93cddb314f1974')
// bugsnagClient.use(bugsnagReact, React)

// const BugsnagErrorBoundary = bugsnagClient.getPlugin('react')

type Props = {
  children: React.ReactNode
  onError?: (error: Error, componentStack: string) => void
}

type ErrorInfo = {
  componentStack: string
}

type State = {
  error: Error | null
  info: ErrorInfo | null
}

export class ErrorBoundary extends React.Component<Props, State> {
  state = {
    error: null,
    info: null
  }

  static getDerivedStateFromError(error: Error) {
    console.error(error)
    return { error }
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    const { onError } = this.props

    if (typeof onError === 'function') {
      try {
        onError.call(this, error, info ? info.componentStack : '')
      } catch (ignoredError) {
        // no op
      }
    }

    this.setState({ error, info })
  }

  render() {
    const { error } = this.state
    const { children } = this.props

    if (error !== null) {
      return (
        <>
          <h6>エラーが発生しました</h6>
          <input
            type="button"
            value="再読み込み"
            onClick={() => window.location.reload()}
          />
          <input
            type="button"
            value="トップに戻る"
            onClick={() => (location.href = process.env.PUBLIC_URL + '/')}
          />
        </>
      )
    }

    // return <BugsnagErrorBoundary>{children}</BugsnagErrorBoundary>
    return <>{children}</>
  }
}

export default ErrorBoundary
