import { Reducer } from 'redux'

import { PolicyResponse } from '~/types/policy'

import { SuccessPayload } from '../core/actions'
import { RequestableState } from '../core/reducers'
import { PolicyReadRequestActionType, PolicyRequestActionType } from './actions'
import PolicyType from './actionTypes'
// import { getSearchResult } from './selectors'

export type PolicyStateType = {
  id: number
  body: string
  created: string
  wasRequested: boolean
} & RequestableState

const initialPolicyState = {
  isLoading: false,
  id: NaN,
  body: '',
  created: '',
  hasError: false,
  wasLoaded: false,
  wasRequested: false
}

/**
 * 規約
 */
export const policy: Reducer<PolicyStateType, PolicyRequestActionType> = (
  state = initialPolicyState,
  action
) => {
  switch (action.type) {
    case PolicyType.REQUEST_POLICY: {
      return {
        ...state,
        isLoading: true
      }
    }
    case PolicyType.SUCCESS_POLICY: {
      const payload = action.payload as SuccessPayload<PolicyResponse>

      return {
        ...state,
        ...payload.data,
        isLoading: false,
        hasError: false,
        wasLoaded: true
      }
    }
    case PolicyType.FAILURE_POLICY: {
      return {
        ...state,
        isLoading: false,
        hasError: true,
        wasLoaded: true
      }
    }
    default: {
      return state
    }
  }
}

export type ReadTermsStateType = {} & RequestableState

const initialReadTermsState = {
  isLoading: false,

  hasError: false,
  wasLoaded: false
}

export const readPolicy: Reducer<
  ReadTermsStateType,
  PolicyReadRequestActionType
> = (state = initialReadTermsState, action) => {
  switch (action.type) {
    case PolicyType.REQUEST_READ_POLICY: {
      return {
        ...state,
        isLoading: true
      }
    }
    case PolicyType.SUCCESS_READ_POLICY: {
      // const payload = action.payload as SuccessPayload<{}>

      return {
        ...state,
        isLoading: false,
        hasError: false,
        wasLoaded: true
      }
    }
    case PolicyType.FAILURE_READ_POLICY: {
      return {
        ...state,
        isLoading: false,
        hasError: true,
        wasLoaded: true
      }
    }
    default: {
      return state
    }
  }
}
