import { Reducer } from 'redux'

import { DSS3Response, DSSDisease, DSSQuery } from '~/types/case'

import { SuccessPayload } from '../core/actions'
import { RequestableState } from '../core/reducers'
import { DSS3SearchActionType } from './actions'
import DSS3Types from './actionTypes'
import { getSearchResult } from './selectors'

export type DSS3StateType = {
  diseases: DSSDisease[] | null
  queries: DSSQuery[] | null
  count: number
} & RequestableState

const initialDSS3State = {
  isLoading: false,
  diseases: null,
  queries: null,
  count: NaN,
  hasError: false,
  wasLoaded: false
}

/**
 * 症例
 */
export const dss3: Reducer<DSS3StateType, DSS3SearchActionType> = (
  state = initialDSS3State,
  action
) => {
  switch (action.type) {
    case DSS3Types.REQUEST_SEARCH: {
      return {
        ...state,
        isLoading: true
      }
    }
    case DSS3Types.SUCCESS_SEARCH: {
      const payload = action.payload as SuccessPayload<DSS3Response>

      return {
        ...state,
        ...getSearchResult(payload),
        isLoading: false,
        hasError: false,
        wasLoaded: true
      }
    }
    case DSS3Types.FAILURE_SEARCH: {
      return {
        ...state,
        isLoading: false,
        hasError: true,
        wasLoaded: true
      }
    }
    default: {
      return state
    }
  }
}
