import React, { FC, lazy, Suspense } from 'react'
import { useSelector } from 'react-redux'
import { BrowserRouter, HashRouter, Route, Routes } from 'react-router-dom'

import LoadingIndicator from '~/components/molecules/LoadingIndicator'
import Config from '~/config'
import NotFoundPage from '~/pages/NotFound'

import Auth from './components/organisms/Auth'
import { RootState } from './modules/reducer'
const IndexPage = lazy(() => import('~/pages/index'))
const QueryPage = lazy(() => import('~/pages/[query]'))
const DemoPage = lazy(() => import('~/pages/demo'))

export const AppRouter: React.FC = () => {
  const { token } = useSelector(
    (state: RootState) => state.auth,
    (left, right) => left.token === right.token
  )
  if (!token) {
    return <Auth />
  }
  return (
    <Suspense fallback={<LoadingIndicator fullpage />}>
      <Routes>
        <Route path="/" element={<IndexPage />} />
        <Route path="/demo" element={<DemoPage />} />
        <Route
          path="/:query/category:categoryNumber/:diseaseName"
          element={<QueryPage />}
        />
        <Route path="/:query/category:categoryNumber" element={<QueryPage />} />
        <Route path="/:query/:diseaseName" element={<QueryPage />} />
        <Route path="/:query/" element={<QueryPage />} />
        <Route element={<NotFoundPage />} />
      </Routes>
    </Suspense>
  )
}

// eslint-disable-next-line
declare let window: any

const App: React.FC = () => {
  if (window.cordova || Config.relativePath) {
    return (
      <HashRouter>
        <AppRouter />
      </HashRouter>
    )
  } else {
    return (
      <BrowserRouter>
        <AppRouter />
      </BrowserRouter>
    )
  }
}

export default App
