export const REQUEST_TERMS = 'request_terms' as const
export const SUCCESS_TERMS = 'success_terms' as const
export const FAILURE_TERMS = 'failure_terms' as const

export const REQUEST_READ_TERMS = 'request_read_terms' as const
export const SUCCESS_READ_TERMS = 'success_read_terms' as const
export const FAILURE_READ_TERMS = 'failure_read_terms' as const

export default {
  REQUEST_TERMS,
  SUCCESS_TERMS,
  FAILURE_TERMS,
  REQUEST_READ_TERMS,
  SUCCESS_READ_TERMS,
  FAILURE_READ_TERMS
}
