import { safeEncode } from '~/utils/search'

import {
  DSS4DiseaseData,
  DSS4DiseaseResponse,
  DSS4Graph,
  DSS4GraphLink,
  DSS4GraphNode,
  DSS4StoryRequest,
  DSS4StoryResponse
} from '../..//types/case'
import { RequestPayload, SuccessPayload } from '../core/actions'
import { DSS4StoryFailure, ScoreUpdateType } from './actions'
import { DSS4StoryStateDataType } from './reducers'

export const getSearchResult = (
  payload: SuccessPayload<DSS4DiseaseResponse>
): DSS4DiseaseData | null => {
  const { data } = payload
  if (!data.result || !data.data) {
    return null
  }
  // 使わないデータはセットしない
  delete data.data.categories
  delete data.data.refineCategories
  delete data.data.refineKeywords
  // data.data.diseases.sort(sortMinScore)
  const ret: DSS4DiseaseData = {
    ...data.data,
    diseases: data.data.diseases.map((d) => {
      return {
        ...d,
        list: []
      }
    })
  }
  for (const d of ret.diseases) {
    delete d.list
  }
  return ret
}

export const setStoryLoadingState = (
  entries: DSS4StoryStateDataType[],
  payload: RequestPayload<DSS4StoryRequest>
): DSS4StoryStateDataType[] => {
  const key = safeEncode(payload.body.disease)
  const prevIndex = entries.findIndex((entry) => entry.key === key)
  // すでにある
  if (prevIndex > -1) {
    const next = Object.assign({}, entries[prevIndex])
    next.isLoading = true
    entries[prevIndex] = next
    return entries
  }
  entries.push({
    key,
    isLoading: true,
    data: null,
    hasError: false,
    wasLoaded: false
  })
  return entries
}

/** story のデータをセット */
export const setStoryData = (
  currentData: DSS4StoryStateDataType[],
  payload: DSS4StoryResponse
): DSS4StoryStateDataType[] => {
  const { data } = payload

  if (!payload.result || !data) {
    return currentData
  }

  const key = safeEncode(data.disease)
  const prevIndex = currentData.findIndex((entry) => entry.key === key)
  if (prevIndex > -1) {
    const next = Object.assign({}, currentData[prevIndex])
    next.isLoading = false
    next.data = data
    next.wasLoaded = true
    next.hasError = false
    currentData[prevIndex] = next
    return currentData
  }
  currentData.push({
    key,
    data,
    wasLoaded: true,
    hasError: false,
    isLoading: false
  })
  return currentData
}

/** エラーを更新 */
export const setStoryError = (
  entries: DSS4StoryStateDataType[],
  payload: DSS4StoryFailure
): DSS4StoryStateDataType[] => {
  const { key } = payload
  const nextIndex = entries.findIndex((item) => item.key === key)
  // 存在しない
  if (nextIndex < 0) {
    return entries
  }
  const next = Object.assign({}, entries[nextIndex])
  next.hasError = true
  next.wasLoaded = true
  next.isLoading = false
  entries[nextIndex] = next
  return entries
}

/** score をあっぷでーとする */
export const findUpdateScore = (
  data: DSS4DiseaseData,
  payload: ScoreUpdateType
): DSS4DiseaseData => {
  const diseaseIndex = data.diseases.findIndex(
    (item) => item.disease === payload.disease
  )
  // 該当データなし
  if (diseaseIndex < 0) {
    return data
  }
  const newData = Object.assign({}, data)
  const disease = data.diseases[diseaseIndex]
  disease.minScore = payload.minScore
  newData.diseases[diseaseIndex] = disease
  // newData.diseases.sort(sortMinScore)
  return newData
}

export const getDiseaseLine = (graph: DSS4Graph): Array<DSS4GraphNode[]> => {
  const { links, nodes } = graph
  const root = nodes.find((node) => node.root)
  if (!root) {
    return []
  }
  // ルートIDをもとに links をフラットにする
  const linkLines = getFlatLinkLine(links, root.id)

  // [[0x00000, 0x00001, 0x00002], [0x00000, 000003, 0x00004]...] を [[症例名, 症例名, 症例名]...] に変換する
  return linkLines.map((lines) => {
    const names = lines
      .map((line) => {
        const n = nodes.find((node) => node.id === line)
        return n ? n : null
      })
      .filter((n) => n !== null)
    return names as DSS4GraphNode[]
  })
}

/* eslint-disable */
const getFlatLinkLine = (
  links: DSS4GraphLink[],
  rootId: string
): Array<string[]> => {
  const rootSet: { [key: string]: object } = {}
  rootSet[rootId] = {}
  const sources = links.filter((l) => l.source === rootId)
  sources.forEach((s) => {
    if (!(s.target in rootSet[rootId])) {
      ;(rootSet[rootId] as any)[s.target] = {}
    }
    const target = (rootSet as any)[rootId][s.target]
    findLines(links, target, s.target)
  })
  return flatten(rootSet)
}

const findLines = (links: DSS4GraphLink[], obj: any, sourceID: string) => {
  const sources = links.filter((l) => l.source === sourceID)
  sources.forEach((l) => {
    if (!(l.target in obj)) {
      obj[l.target] = {}
    }
    const child = obj[l.target]
    findLines(links, child, l.target)
  })
}

const flatten = (obje: object): Array<string[]> => {
  const result: string[][] = []
  const findNode = (obj: object, root: string[]) => {
    if (Object.keys(obj).length > 0) {
      Object.keys(obj).forEach((key) => {
        const tmpAry: string[] = []
        const newRoot = tmpAry.concat(root)
        newRoot.push(key)
        findNode((obj as any)[key], newRoot)
      })
    } else {
      result.push(root)
    }
  }
  findNode(obje, [])
  return result
}
/* eslint-enable */
