import { DSS3Response, DSSDisease, DSSQuery } from '~/types/case'

import { SuccessPayload } from '../core/actions'

/**
 * レスポンスから適宜取り出して返す
 **/
export const getSearchResult = (
  payload: SuccessPayload<DSS3Response>
): {
  diseases: DSSDisease[] | null
  queries: DSSQuery[] | null
  count: number
} => {
  const { data } = payload
  const { queries, diseases, count } = data
  diseases.forEach((disease) => {
    disease.version = 'v1'
  })
  return {
    diseases,
    queries,
    count
  }
}
