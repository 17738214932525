import _ from 'lodash'
import React, { useEffect } from 'react'
import ReactMarkdown from 'react-markdown'
import { useDispatch, useSelector } from 'react-redux'

import AnyDialog from '~/components/molecules/AnyDialog'
import { RootState } from '~/modules/reducer'
import { requestReadTerms, requestTerms } from '~/modules/terms/actions'

const usage = `
本システムの利用にあたって、下記のような検索単語の入力をお願いしています。

## 検索単語想定
### 適切
- 発熱 血小板減少 意識障害 （SFTSなどが検索結果として示されます）
- 発熱 頭痛 （髄膜炎などが検索結果として示されます）
- 糖尿病 肝硬変 心不全（ヘモクロマトーシスなどが検索結果として示されます）

### 不適切
- × 原因不明の発熱 周期性の発熱 （形容詞が入っているため不適切です。発熱と入力してください）

## 検索結果
検索は、４つの異なるアルゴリズムにより行われます。カテゴリー1～4は下記のような特徴があります。
- カテゴリー 1: 単一疾患の複数の症例報告（和集合）について検索します。
- カテゴリー 2: 単一疾患の単一の症例報告について検索します。
- カテゴリー 3: 合併症をもつ単一の症例報告について検索します。
- カテゴリー 4: 複数の症例報告の論理を組み合わせて推測しています。
`

const notes = `
本データベースは日本内科学会地方会の症例報告であるため、対象は内科の希少疾患に限られます。このため感冒や筋緊張頭痛などの日常的な疾患は含まれていないことがあります。
また、当システムの利用にあたり、ユーザーは自己の判断によって本システムを利用し、診療行為及び診断を行ってください。同ユーザーの行為によってユーザーおよびユーザーの患者・医療機関等に一次的または二次的に損害が生じた場合であっても、本システム・コンテンツ制作・運営にかかわった者は一切の責任を負わないものとします。その他、利用にあたっては下記の利用規約を了解したものとさせていただきます。
`

const all = `
# 利用にあたって
${usage}

---

# 注意事項
${notes}

---

# 利用規約
`

const contents = {
  all,
  usage,
  notes,
  terms: ''
}

export interface UsageDialogProps {
  open: boolean
  onClose: () => void
  content?: 'all' | 'usage' | 'notes' | 'terms'
}

const UsageDialog: React.FC<UsageDialogProps> = ({
  open,
  onClose,
  content = 'all'
}: UsageDialogProps) => {
  let title
  if (content == 'all') {
    title = null
  } else if (content == 'usage') {
    title = '検索について'
  } else if (content == 'notes') {
    title = '注意事項'
  } else if (content == 'terms') {
    title = '利用規約'
  }
  const dispatch = useDispatch()
  const { body, wasLoaded, wasRequested } = useSelector(
    (state: RootState) => state.terms,
    (prev, next) => _.isEqual(prev, next)
  )
  const { id } = useSelector((state: RootState) => state.terms)
  const { token, hasReadLatestTos } = useSelector(
    (state: RootState) => state.auth
  )

  contents.terms = body
  contents.all = all + body
  useEffect(() => {
    if (content === 'all' || content === 'terms') {
      if (!wasRequested && !wasLoaded) {
        dispatch(requestTerms())
      }
      // ログイン中に規約が書き換わった時
      if (wasLoaded && token && !hasReadLatestTos) {
        dispatch(requestReadTerms(id))
      }
    }
  }, [wasLoaded, wasRequested])

  return (
    <AnyDialog onClose={onClose} open={open} title={title} maxWidth="md">
      <ReactMarkdown>{contents[content]}</ReactMarkdown>
    </AnyDialog>
  )
}

export default UsageDialog
