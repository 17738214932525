import { put } from 'redux-saga/effects'

import { FetchQueue } from '~/service/api/queue'
import { RequestContext } from '~/types/network'
import { PolicyRequest, PolicyResponse } from '~/types/policy'

import APIService from '../../service/api'
import { Action, RequestPayload } from '../core/actions'
import { getToken } from '../core/saga'
import { policyActions, readPolicyActions } from './actions'

/**
 * 利用規約取得
 */
export function* getPolicy() {
  // @ts-expect-error: TS7057: 'yield' expression implicitly results in an 'any'
  const token = yield getToken()
  const req: RequestContext<PolicyRequest> = {
    token,
    noRequiredToken: true
  }
  let response
  // リクエスト実行
  try {
    // @ts-expect-error: TS7057: 'yield' expression implicitly results in an 'any'
    response = yield FetchQueue(APIService['getLastPolicy'], req)
  } catch (error) {
    response = error
  }

  if (response.error) {
    yield put(policyActions.failurePolicy({}))
  } else {
    yield put(policyActions.successPolicy({ data: response }))
    setStorage(response)
  }
}

/**
 * 利用規約既読
 */
export function* readPolicy(args: Action<RequestPayload<{ id: number }>>) {
  // @ts-expect-error: TS7057: 'yield' expression implicitly results in an 'any'
  const token = yield getToken()
  const req: RequestContext<{}> = {
    token,
    pathParams: {
      id: args.payload.body.id
    }
  }
  let response
  // リクエスト実行
  try {
    // @ts-expect-error: TS7057: 'yield' expression implicitly results in an 'any'
    response = yield FetchQueue(APIService['readPolicy'], req)
  } catch (error) {
    response = error
  }

  if (response.error) {
    yield put(readPolicyActions.failureReadPolicy({}))
  } else {
    yield put(readPolicyActions.successReadPolicy({ data: response }))
  }
}

const setStorage = (response: PolicyResponse) => {
  if (!response.body) {
    return
  }
  const { id } = response
  localStorage.setItem('policy_id', String(id))
}
