import {
  DSS1DiseaseRequest,
  DSS1DiseaseResponse,
  DSS1Response,
  DSS1V2Response,
  DSSRequest
} from '~/types/case'

import { Action, RequestPayload, SuccessPayload } from '../core/actions'
import DSS1Type from './actionTypes'

// TODO ぱらめーたーふやす
export type DSS1RequestInput = {
  q: string[]
}
export type DSS1SearchRequest = {} & RequestPayload<DSSRequest>
export type DSS1V1SearchSuccess = {} & SuccessPayload<DSS1Response>
export type DSS1V1SearchFailure = {}

export const requestDSS1V1 = (
  payload: DSS1RequestInput
): Action<DSS1SearchRequest> => ({
  type: DSS1Type.REQUEST_SEARCH_V1,
  payload: {
    body: {
      q: payload.q.join(' ')
    }
  }
})

export const successDSS1V1 = (
  payload: DSS1V1SearchSuccess
): Action<DSS1V1SearchSuccess> => ({
  type: DSS1Type.SUCCESS_SEARCH_V1,
  payload
})

export const failureDSS1V1 = (
  payload: DSS1V1SearchFailure
): Action<DSS1V1SearchFailure> => ({
  type: DSS1Type.FAILURE_SEARCH_V1,
  payload
})

export type DSS1V1SearchActionType = ReturnType<
  typeof requestDSS1V1 | typeof successDSS1V1 | typeof failureDSS1V1
>

export const dss1V1Actions = {
  requestDSS1V1,
  successDSS1V1,
  failureDSS1V1
}

// --------------------------
// V2
// --------------------------

export type DSS1V2SearchSuccess = {} & SuccessPayload<DSS1V2Response>

export const requestDSS1 = (
  payload: DSS1RequestInput
): Action<DSS1SearchRequest> => ({
  type: DSS1Type.REQUEST_SEARCH,
  payload: {
    body: {
      q: payload.q.join(' ')
    }
  }
})

export const successDSS1 = (
  payload: DSS1V1SearchSuccess
): Action<DSS1V1SearchSuccess> => ({
  type: DSS1Type.SUCCESS_SEARCH,
  payload
})

export const failureDSS1 = (
  payload: DSS1V1SearchFailure
): Action<DSS1V1SearchFailure> => ({
  type: DSS1Type.FAILURE_SEARCH,
  payload
})

export type DSS1SearchActionType = ReturnType<
  typeof requestDSS1 | typeof successDSS1 | typeof failureDSS1
>

export const dss1Actions = {
  requestDSS1,
  successDSS1,
  failureDSS1
}

// ---------------------------
// DSS1 disease
// ---------------------------

export type DSS1DiseaseRequestInput = {
  q: string[]
  id: string
}

export type DSS1DiseaseRequestPayload = {} & RequestPayload<DSS1DiseaseRequest>
export type DSS1DiseaseSuccessPayload = {} & SuccessPayload<DSS1DiseaseResponse>
export type DSS1DiseaseFailurePayload = {
  key: string
}

export const requestDSS1Disease = (
  payload: DSS1DiseaseRequestInput
): Action<DSS1DiseaseRequestPayload> => ({
  type: DSS1Type.REQUEST_DISEASE,
  payload: {
    body: {
      q: payload.q.join(' '),
      id: payload.id
    }
  }
})

export const successDSS1Disease = (
  payload: DSS1DiseaseSuccessPayload
): Action<DSS1DiseaseSuccessPayload> => ({
  type: DSS1Type.SUCCESS_DISEASE,
  payload
})

export const failureDSS1Disease = (
  payload: DSS1DiseaseFailurePayload
): Action<DSS1DiseaseFailurePayload> => ({
  type: DSS1Type.FAILURE_DISEASE,
  payload
})

export type DSS1DiseaseActionType = ReturnType<
  | typeof requestDSS1Disease
  | typeof successDSS1Disease
  | typeof failureDSS1Disease
>

export const dss1DiseaseActions = {
  requestDSS1Disease,
  successDSS1Disease,
  failureDSS1Disease
}
