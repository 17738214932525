import React, { useState } from 'react'
import styled from 'styled-components'

const Container = styled.div``

export interface FontSizeChangerProps {
  children: React.ReactNode
}

const targetElement = document.documentElement
const defaultFontSize = window
  .getComputedStyle(targetElement, null)
  .getPropertyValue('font-size')

const getFontSizeDetails = (fontSize: string) => {
  const value = parseFloat(fontSize)
  const unit = fontSize.toString().replace(value.toString(), '')

  return {
    value,
    unit
  }
}

export const FontSizeChanger: React.FC<FontSizeChangerProps> = ({
  children
}: FontSizeChangerProps) => {
  const [isLarge, setIsLarge] = useState(false)
  const defaultFontSizeValue = getFontSizeDetails(defaultFontSize).value

  const handleChangeFontSize = () => {
    const newFontSize = isLarge
      ? defaultFontSizeValue
      : defaultFontSizeValue + 2
    targetElement.style.fontSize = newFontSize + 'px'
    setIsLarge(!isLarge)
  }

  return <Container onClick={handleChangeFontSize}>{children}</Container>
}

export default FontSizeChanger
