import { safeEncode } from '~/utils/search'

import {
  CaseDetailRequestInput,
  CaseFailurePayload,
  CaseSuccessPayload
} from './actions'
import { CaseStateData } from './reducers'

/** 読み込みステータスをセット */
export const setCaseDetailLoading = (
  cases: CaseStateData[],
  // TODO 堅
  payload: CaseDetailRequestInput
): CaseStateData[] => {
  console.log(payload)
  const key = safeEncode(payload.body)
  const prevIndex = cases.findIndex((entry) => entry.key === key)
  // すでにある
  if (prevIndex > -1) {
    const next = Object.assign({}, cases[prevIndex])
    next.isLoading = true
    cases[prevIndex] = next
    return cases
  }
  cases.push({
    key,
    isLoading: true,
    data: null,
    hasError: false,
    wasLoaded: false
  })
  return cases
}

/* 読み込み結果をセット */
export const setCaseDetailData = (
  cases: CaseStateData[],
  payload: CaseSuccessPayload
): CaseStateData[] => {
  console.log(payload)
  const key = safeEncode(payload.data.name)
  const prevIndex = cases.findIndex((entry) => entry.key === key)
  // すでにある
  // きほんある恥ず
  if (prevIndex > -1) {
    const next = Object.assign({}, cases[prevIndex])
    next.isLoading = false
    next.hasError = false
    next.wasLoaded = true
    next.data = payload.data
    cases[prevIndex] = next
    return cases
  }
  cases.push({
    key,
    isLoading: false,
    data: null,
    hasError: false,
    wasLoaded: false
  })
  return cases
}

/** エラーをセット */
export const setCaseDetailError = (
  cases: CaseStateData[],
  // TODO 堅
  payload: CaseFailurePayload
): CaseStateData[] => {
  const key = safeEncode(payload.key)
  const nextIndex = cases.findIndex((entry) => entry.key === key)
  // あまり考えられないが存在しない
  if (nextIndex < 0) {
    return cases
  }
  const next = cases[nextIndex]
  next.errorBody = payload.error
  next.hasError = true
  next.wasLoaded = true
  next.isLoading = false

  cases[nextIndex] = next
  return cases
}
