import { put } from 'redux-saga/effects'

import { FetchQueue } from '~/service/api/queue'
import { DSS4StoryResponse } from '~/types/case'
import { RequestContext } from '~/types/network'

import APIService from '../../service/api'
import { Action, RequestPayload } from '../core/actions'
import { getToken } from '../core/saga'
import {
  diseaseActions,
  DSS4DiseaseRequest,
  DSS4StoryRequest,
  storyActions
} from './actions'

/**
 * 症例取得
 */
// TODO type
export function* getDisease(args: Action<DSS4DiseaseRequest>) {
  // @ts-expect-error: TS7057: 'yield' expression implicitly results in an 'any'
  const token = yield getToken()
  const req = {
    body: args.payload.body,
    token
  }
  let response
  // リクエスト実行
  // TODO 後で書き直す
  try {
    // @ts-expect-error: TS7057: 'yield' expression implicitly results in an 'any'
    response = yield FetchQueue(APIService['searchCat4'], req)
  } catch (error) {
    response = error
  }

  if (response.error) {
    yield put(diseaseActions.failureDSS4Disease({}))
  } else {
    yield put(diseaseActions.successDSS4Disease({ data: response }))
  }
}

/**
 * ストーリーを取得する
 **/
export function* getStory(args: Action<RequestPayload<DSS4StoryRequest>>) {
  // @ts-expect-error: TS7057: 'yield' expression implicitly results in an 'any'
  const token = yield getToken()

  const req: RequestContext<DSS4StoryRequest> = {
    body: args.payload.body,
    token
  }
  let response
  // リクエスト実行
  try {
    // @ts-expect-error: TS7057: 'yield' expression implicitly results in an 'any'
    response = yield FetchQueue(APIService['storyCat4'], req)
  } catch (error) {
    response = error
  }

  if (response.error) {
    yield put(
      storyActions.failureDSS4Story({ key: args.payload.body.body.disease })
    )
  } else {
    yield put(storyActions.successDSS4Story(response as DSS4StoryResponse))
    if (response.data) {
      yield put(
        diseaseActions.updateScore({
          disease: response.data.disease,
          minScore: response.data.minScore
        })
      )
    }
  }
}
