import { CaseDetailResponse } from '~/types/case'

import { Action } from '../core/actions'
import CaseType from './actionTypes'

// TODO ぱらめーたーふやす
export type CaseDetailRequestInput = {
  body: string
}

export type CaseSuccessPayload = { data: CaseDetailResponse }
export type CaseFailurePayload = {
  key: string
  error?: string
}

export const requestCase = (name: string): Action<CaseDetailRequestInput> => ({
  type: CaseType.REQUEST_CASE,
  payload: {
    body: name
  }
})

export const successCase = (
  payload: CaseSuccessPayload
): Action<CaseSuccessPayload> => ({
  type: CaseType.SUCCESS_CASE,
  payload
})

export const failureCase = (
  payload: CaseFailurePayload
): Action<CaseFailurePayload> => ({
  type: CaseType.FAILURE_CASE,
  payload
})

export type CaseActions = ReturnType<
  typeof requestCase | typeof successCase | typeof failureCase
>

export const caseActions = {
  requestCase,
  successCase,
  failureCase
}
