import { put } from 'redux-saga/effects'

// import { FetchQueue } from '~/service/api/queue'
import { DSSRequest } from '~/types/case'
import { RequestContext } from '~/types/network'

import APIService from '../../service/api'
import { Action, RequestPayload } from '../core/actions'
import { getToken } from '../core/saga'
import {
  dss1Actions,
  dss1DiseaseActions,
  DSS1DiseaseRequestPayload,
  dss1V1Actions
} from './actions'

/**
 * カテゴリ1取得
 */
export function* getDSS1V1(args: Action<RequestPayload<DSSRequest>>) {
  // @ts-expect-error: TS7057: 'yield' expression implicitly results in an 'any'
  const token = yield getToken()

  const req: RequestContext<DSSRequest> = {
    body: args.payload.body,

    token
  }
  let response
  // リクエスト実行
  try {
    // @ts-expect-error: TS7057: 'yield' expression implicitly results in an 'any'
    response = yield APIService['searchCat1'](req)
    // response = yield FetchQueue(APIService['searchCat1'], req)
  } catch (error) {
    response = error
  }

  if (response.error) {
    yield put(dss1V1Actions.failureDSS1V1({}))
  } else {
    yield put(dss1V1Actions.successDSS1V1({ data: response }))
  }
}

export function* getDSS1(args: Action<RequestPayload<DSSRequest>>) {
  // @ts-expect-error: TS7057: 'yield' expression implicitly results in an 'any'
  const token = yield getToken()

  const req: RequestContext<DSSRequest> = {
    body: args.payload.body,
    token
  }
  let response
  // リクエスト実行
  try {
    // @ts-expect-error: TS7057: 'yield' expression implicitly results in an 'any'
    response = yield APIService['searchCat1V2'](req)
    // response = yield FetchQueue(APIService['searchCat1V2'], req)
  } catch (error) {
    response = error
  }

  if (response.error) {
    yield put(dss1Actions.failureDSS1({}))
  } else {
    yield put(dss1Actions.successDSS1({ data: response }))
  }
}

export function* getDSS1Disease(args: Action<DSS1DiseaseRequestPayload>) {
  // @ts-expect-error: TS7057: 'yield' expression implicitly results in an 'any'
  const token = yield getToken()
  type Req = {
    q: string
  }
  const req: RequestContext<Req> = {
    body: { q: args.payload.body.q },
    token,
    pathParams: {
      id: args.payload.body.id
    }
  }
  let response
  // リクエスト実行
  try {
    // @ts-expect-error: TS7057: 'yield' expression implicitly results in an 'any'
    response = yield APIService['searchCat1V2Disease'](req)
  } catch (error) {
    response = error
  }

  if (response.error) {
    yield put(
      dss1DiseaseActions.failureDSS1Disease({ key: args.payload.body.id })
    )
  } else {
    yield put(dss1DiseaseActions.successDSS1Disease({ data: response }))
  }
}
