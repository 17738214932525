import { Reducer } from 'redux'

import {
  DSS2DiseaseResponse,
  DSS2Response,
  DSS2V2Response,
  DSSDisease,
  DSSQuery,
  DSSV2Disease
} from '~/types/case'

import { SuccessPayload } from '../core/actions'
import { RequestableState } from '../core/reducers'
import {
  DSS2DiseaseActionType,
  DSS2DiseaseFailurePayload,
  DSS2DiseaseRequestPayload,
  DSS2SearchActionType,
  DSS2V1SearchActionType
} from './actions'
import DSS2Types from './actionTypes'
import {
  getSearchResult,
  getSearchResultV1,
  setDiseaseData,
  setDiseaseError,
  setDiseaseLoadingState
} from './selectors'

export type DSS2V1StateType = {
  diseases: DSSDisease[] | null
  queries: DSSQuery[] | null
  count: number
} & RequestableState

const initialDSS2V1State = {
  isLoading: false,
  diseases: null,
  queries: null,
  count: NaN,
  hasError: false,
  wasLoaded: false
}

/**
 * 症例
 */
export const dss2V1: Reducer<DSS2V1StateType, DSS2V1SearchActionType> = (
  state = initialDSS2V1State,
  action
) => {
  switch (action.type) {
    case DSS2Types.REQUEST_SEARCH_V1: {
      return {
        ...state,
        isLoading: true
      }
    }
    case DSS2Types.SUCCESS_SEARCH_V1: {
      const payload = action.payload as SuccessPayload<DSS2Response>

      return {
        ...state,
        ...getSearchResultV1(payload),
        isLoading: false,
        hasError: false,
        wasLoaded: true
      }
    }
    case DSS2Types.FAILURE_SEARCH_V1: {
      return {
        ...state,
        isLoading: false,
        hasError: true,
        wasLoaded: true
      }
    }
    default: {
      return state
    }
  }
}

export type DSS2StateType = {
  diseases: DSSV2Disease[] | null
  queries: DSSQuery[] | null
  count: number
} & RequestableState

const initialDSS2State = {
  isLoading: false,
  diseases: null,
  queries: null,
  count: NaN,
  hasError: false,
  wasLoaded: false
}

export const dss2: Reducer<DSS2StateType, DSS2SearchActionType> = (
  state = initialDSS2State,
  action
) => {
  switch (action.type) {
    case DSS2Types.REQUEST_SEARCH: {
      return {
        ...state,
        isLoading: true
      }
    }
    case DSS2Types.SUCCESS_SEARCH: {
      const payload = action.payload as SuccessPayload<DSS2V2Response>

      return {
        ...state,
        ...getSearchResult(payload),
        isLoading: false,
        hasError: false,
        wasLoaded: true
      }
    }
    case DSS2Types.FAILURE_SEARCH: {
      return {
        ...state,
        isLoading: false,
        hasError: true,
        wasLoaded: true
      }
    }
    default: {
      return state
    }
  }
}

export type DSS2DiseaseDataType = {
  key: string
  hasError: boolean
  wasLoaded: boolean
  isLoading: boolean
  data: DSS2DiseaseResponse | null
}

export type DSS2DiseaseStateType = {
  entries: DSS2DiseaseDataType[]
}

const initialDSS2DiseaseState: DSS2DiseaseStateType = {
  entries: []
}

export const dss2Disease: Reducer<
  DSS2DiseaseStateType,
  DSS2DiseaseActionType
> = (state = initialDSS2DiseaseState, action) => {
  switch (action.type) {
    case DSS2Types.REQUEST_DISEASE: {
      return {
        ...state,
        entries: setDiseaseLoadingState(
          state.entries,
          action.payload as DSS2DiseaseRequestPayload
        )
      }
    }
    case DSS2Types.SUCCESS_DISEASE: {
      const payload = action.payload as SuccessPayload<DSS2DiseaseResponse>

      return {
        ...state,
        entries: setDiseaseData(state.entries, payload)
      }
    }
    case DSS2Types.FAILURE_DISEASE: {
      const payload = action.payload as DSS2DiseaseFailurePayload
      return {
        ...state,
        entries: setDiseaseError(
          state.entries,
          payload as DSS2DiseaseFailurePayload
        )
      }
    }
    default: {
      return state
    }
  }
}
