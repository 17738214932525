import React, { createContext, Dispatch, FC, useReducer } from 'react'

import uiReducers, { initialState, UIState } from './reducer'
// import { reCreateDispatch } from '../sagas/core';
// import { sttSagas } from '../sagas';

/**
 * 最終的には redux に入れたほうがいいけど独立性をと要望されたので別。
 * redux を多重で動かす方法探るより単一(に近い)リソースなので react の機能使う
 */

export interface UIContextProps {
  uiState: UIState
  // react 本体の実装がそうなってるから無理
  // eslint-disable-next-line
  uiDispatch: Dispatch<any>
}

// context
// redux の store 代わり
// 将来のこと考えると SocketStore とかにしといたほうがいい気もしつつ、現状でのわかりやすさ優先
export const UIStore = createContext<UIContextProps>({
  uiState: initialState,
  // eslint-disable-next-line
  uiDispatch: () => {}
})

export type UIProviderProps = {}

/** プロバイダ */
export const UIProvider: FC<UIProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(uiReducers, initialState)
  return (
    <UIStore.Provider
      value={{
        uiState: state,
        uiDispatch: dispatch
      }}
    >
      {children}
    </UIStore.Provider>
  )
}
