import { PolicyRequest, PolicyResponse } from '~/types/policy'

import { Action, RequestPayload, SuccessPayload } from '../core/actions'
import PolicyType from './actionTypes'

// import { DSSRequest, TermsResponse } from '~/types/case'

export type PolicyRequestPayload = {} & RequestPayload<PolicyRequest>
export type PolicyRequestSuccess = {} & SuccessPayload<PolicyResponse>
export type PolicyRequestFailure = {}

export const requestPolicy = (): Action<PolicyRequest> => ({
  type: PolicyType.REQUEST_POLICY,
  payload: {}
})

export const successPolicy = (
  payload: PolicyRequestSuccess
): Action<PolicyRequestSuccess> => ({
  type: PolicyType.SUCCESS_POLICY,
  payload
})

export const failurePolicy = (
  payload: PolicyRequestFailure
): Action<PolicyRequestFailure> => ({
  type: PolicyType.FAILURE_POLICY,
  payload
})

export type PolicyRequestActionType = ReturnType<
  typeof requestPolicy | typeof successPolicy | typeof failurePolicy
>

export const policyActions = {
  requestPolicy,
  successPolicy,
  failurePolicy
}

export type PolicyReadRequestPayload = {} & RequestPayload<{ id: number }>

export const requestReadPolicy = (
  id: number
): Action<PolicyReadRequestPayload> => ({
  type: PolicyType.REQUEST_READ_POLICY,
  payload: {
    body: { id }
  }
})

export const successReadPolicy = (
  payload: PolicyRequestSuccess
): Action<PolicyRequestSuccess> => ({
  type: PolicyType.SUCCESS_READ_POLICY,
  payload
})

export const failureReadPolicy = (
  payload: PolicyRequestFailure
): Action<PolicyRequestFailure> => ({
  type: PolicyType.FAILURE_READ_POLICY,
  payload
})

export type PolicyReadRequestActionType = ReturnType<
  typeof requestReadPolicy | typeof successReadPolicy | typeof failureReadPolicy
>

export const readPolicyActions = {
  requestReadPolicy,
  successReadPolicy,
  failureReadPolicy
}
