import { select } from 'redux-saga/effects'

import { RootState } from '~/modules/reducer'

export function* getToken() {
  // @ts-expect-error: TS7057: 'yield' expression implicitly results in an 'any'
  const state = yield select()
  const { auth } = state as RootState
  const { token } = auth

  return token
}
