import Config from '~/config'
import { SearchHistory } from '~/types/suggest'
const { STORAGE_KEY, MAX_HISTORY_NUM } = Config.searchHistory

/** 検索履歴を localstorage に格納し、その結果を返す */
export const setSearchHistory = (q: string): { keywords: SearchHistory[] } => {
  // console.log(q)
  const prevKeywords = localStorage.getItem(STORAGE_KEY)
  const keywords = prevKeywords
    ? (JSON.parse(prevKeywords) as SearchHistory[])
    : []
  // MAX 以上は保持しない
  if (keywords.length >= MAX_HISTORY_NUM) {
    keywords.shift()
  }
  // 同じものが含まれなければ保存する
  if (keywords.findIndex((s) => s.query === q) < 0) {
    keywords.push({ query: q, searchedAt: Date.now() })
    localStorage.setItem(STORAGE_KEY, JSON.stringify(keywords))
  }
  return { keywords }
}

/** localstorage から検索履歴を取る */
export const getSearchHistory = (): { keywords: SearchHistory[] } => {
  const storagedHistory = localStorage.getItem(STORAGE_KEY)
  if (!storagedHistory) {
    return { keywords: [] }
  }
  let keywords = JSON.parse(storagedHistory)
  // 復元したのに string[] ではなかったパターン
  if (!(keywords instanceof Array)) {
    keywords = []
  }
  return {
    keywords
  }
}
