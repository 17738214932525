import _ from 'lodash'
import React, { useRef } from 'react'
import { useWindowSize } from 'rooks'
import styled from 'styled-components'

import LocationObserver from '~/observer/LocationObserver'
import { theme } from '~/theme'
import { refScrollReset } from '~/utils/refScrollReset'

const LEFT_PANE_WIDTH = 360
const RIGHT_PANE_WIDTH = 252
const PANE_BORDER_WIDTH = 1
export const NAVIGATOR_HEIGHT = 56

// Appbar の各ブレークポイントの高さを利用して残りの高さを算出する
// eslint-disable-next-line
const traverseMinHeightToHeight = (obj: any, hasNavigator: boolean): string => {
  const navigatorHeihgt = hasNavigator ? NAVIGATOR_HEIGHT : 0
  for (const k in obj) {
    if (obj[k] && typeof obj[k] === 'object') {
      traverseMinHeightToHeight(obj[k], hasNavigator)
    } else {
      if (k === 'minHeight') {
        obj['height'] = `calc(100% - ${obj[k]}px - ${navigatorHeihgt}px)`
        delete obj[k]
      }
    }
  }
  return obj
}

const HeightWithAppbar = (hasNavigator: boolean) => {
  return traverseMinHeightToHeight(
    _.cloneDeep(theme.mixins.toolbar as object),
    hasNavigator
  )
}

interface ContainerProps {
  testId: string
}

const Container = styled.div.attrs<ContainerProps>((props) => ({
  'data-testid': props.testId
}))<ContainerProps>`
  height: 100%;
`

interface ContainerRowProps {
  hasAppBar: boolean
  hasNavigator: boolean
}

const ContainerRow = styled.div<ContainerRowProps>`
  display: flex;
  position: relative;
  ${(props) =>
    props.hasAppBar ? HeightWithAppbar(props.hasNavigator) : { height: '100%' }}
`

const LeftPane = styled.div`
  background-color: white;
  position: sticky;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  flex: 0 1 ${LEFT_PANE_WIDTH + PANE_BORDER_WIDTH}px;
  border-right: ${PANE_BORDER_WIDTH}px solid rgba(0, 0, 0, 0.1);
  height: 100%;
`

const RightPane = styled.div`
  background-color: white;
  position: sticky;
  order: 2;
  flex: 0 0 ${RIGHT_PANE_WIDTH + PANE_BORDER_WIDTH}px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  border-left: ${PANE_BORDER_WIDTH}px solid rgba(0, 0, 0, 0.1);
  height: 100%;
`

interface ContentProps {
  hasLeftPane: boolean
  hasRightPane: boolean
}

const Content = styled.main<ContentProps>`
  position: relative;
  order: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: ${(props) =>
    props.hasLeftPane
      ? `
  calc( 100% - ${LEFT_PANE_WIDTH + 1}px )`
      : `
  100%
  `};
  height: 100%;
`

export interface LayoutProps {
  appBar?: React.ReactNode
  navigator?: React.ReactNode
  leftPane?: React.ReactNode
  rightPane?: React.ReactNode
  testId?: string
  children: React.ReactNode
}

export const AppLayout: React.FC<LayoutProps> = ({
  appBar,
  navigator,
  leftPane,
  rightPane,
  children,
  testId = 'AppLayout'
}: LayoutProps) => {
  // const leftRef = useRef(null)
  const rightRef = useRef(null)
  const contentRef = useRef(null)

  const { innerWidth } = useWindowSize()
  if (innerWidth < theme.breakpoints.width('sm')) {
    leftPane = null
  }

  return (
    <LocationObserver
      handlers={[
        (pathname, prevPath) => {
          if (
            pathname &&
            prevPath &&
            pathname.split('/').length >= prevPath.split('/').length
          ) {
            // console.log('exec scroll')
            refScrollReset([
              // leftRef,
              rightRef,
              contentRef
            ])
          }
        }
      ]}
    >
      <Container testId={testId}>
        {appBar && appBar}
        {navigator && navigator}
        <ContainerRow hasAppBar={!!appBar} hasNavigator={!!navigator}>
          {leftPane && (
            <LeftPane
            // ref={leftRef}
            >
              {leftPane}
            </LeftPane>
          )}
          {rightPane && <RightPane ref={rightRef}>{rightPane}</RightPane>}
          <Content
            hasLeftPane={!!leftPane}
            hasRightPane={!!rightPane}
            ref={contentRef}
            id="contentPane"
          >
            {children}
          </Content>
        </ContainerRow>
      </Container>
    </LocationObserver>
  )
}

export default AppLayout
