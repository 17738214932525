const Config = {
  name: 'default',
  dummyToken: false,
  relativePath: false,
  ui: {
    // 連続タイプで大量にリクエストを送らないようにインターバルを設ける(ミリ秒)
    SUGGEST_INTERVAL: 600,
    // インターバルを満たしてなくても規定文字数より多く入力があった場合はリクエストを実行する
    SUGGEST_WORD_THRESHOLD: 6
  },
  auth: {
    AUTH_HOST: `//${location.hostname}/v1`,
    AUTH_URL: '/auth/login/',
    REFRESH_URL: '/auth/refresh/'
  },
  searchHistory: {
    STORAGE_KEY: 'search_history',
    MAX_HISTORY_NUM: 3
  }
}

export default Config
