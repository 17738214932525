import { HTTPMethod } from '../types/network'

export type PathParams = {
  paramName: string
  require?: boolean
  default?: string
}

export type Endpoint = {
  name: string
  path: string
  method: HTTPMethod
  description: string
  pathParams: PathParams[]
}

export type Bundle = {
  env: string
  basePath: string
  defaultVersion: string
  http: {
    endpoints: Endpoint[]
    optionalHeader: (header: Headers) => Headers
  }
}

const Bundler: Bundle = {
  env: 'default',
  basePath: `${location.hostname}/{version}`,
  defaultVersion: 'v1',
  http: {
    optionalHeader: (header) => {
      return header
    },
    endpoints: [
      {
        name: 'getUser',
        path: '',
        method: 'GET',
        description: 'ユーザー取得',
        pathParams: []
      },
      {
        name: 'listComments',
        path: '',
        method: 'GET',
        description: 'コメント取得',
        pathParams: []
      },
      {
        name: 'suggest',
        path: '/suggest/',
        method: 'GET',
        description: 'サジェスト取得',
        pathParams: []
      },
      {
        name: 'summary',
        path: '/summary/',
        method: 'GET',
        description: '検索候補(もしかして:●●)',
        pathParams: []
      },
      {
        name: 'searchCat1',
        path: '/category1/',
        method: 'GET',
        description: 'カテゴリ1検索',
        pathParams: []
      },
      {
        name: 'searchCat2',
        path: '/category2/',
        method: 'GET',
        description: 'カテゴリ1検索',
        pathParams: []
      },
      {
        name: 'searchCat3',
        path: '/category3/',
        method: 'GET',
        description: 'カテゴリ3検索',
        pathParams: []
      },
      {
        name: 'searchCat4',
        path: '/category4/disease_new/',
        method: 'GET',
        description: 'カテゴリ4検索',
        pathParams: []
      },
      {
        name: 'storyCat4',
        path: '/category4/story/',
        method: 'GET',
        description: 'カテゴリ4検索',
        pathParams: []
      },
      {
        name: 'caseDetail',
        path: '/case/{name}/',
        method: 'GET',
        description: '症例本文',
        pathParams: [
          {
            paramName: 'name',
            require: true
          }
        ]
      },
      {
        name: 'searchCat1V2',
        path: '/category1/',
        method: 'GET',
        description: 'カテゴリ1V2',
        pathParams: [
          {
            paramName: 'version',
            default: 'v2'
          }
        ]
      },
      {
        name: 'searchCat1V2Disease',
        path: '/category1/diseases/{id}/',
        method: 'GET',
        description: 'カテゴリ1V2',
        pathParams: [
          {
            paramName: 'id',
            require: true
          },
          {
            paramName: 'version',
            default: 'v2'
          }
        ]
      },
      {
        name: 'searchCat2V2',
        path: '/category2/',
        method: 'GET',
        description: 'カテゴリ2V2',
        pathParams: [
          {
            paramName: 'version',
            default: 'v2'
          }
        ]
      },
      {
        name: 'searchCat2V2Disease',
        path: '/category2/diseases/{id}/',
        method: 'GET',
        description: 'カテゴリ2V2',
        pathParams: [
          {
            paramName: 'id',
            require: true
          },
          {
            paramName: 'version',
            default: 'v2'
          }
        ]
      },
      {
        name: 'getTerms',
        path: '/termsofservices/latest/',
        method: 'GET',
        description: '利用規約取得',
        pathParams: []
      },
      {
        name: 'readTerms',
        path: '/termsofservices/{id}/read/',
        method: 'POST',
        description: '利用規約既読',
        pathParams: [
          {
            paramName: 'id',
            require: true
          }
        ]
      },
      {
        name: 'getLastPolicy',
        path: '/privacypolicies/latest/',
        method: 'GET',
        description: 'プライバシーポリシー取得',
        pathParams: []
      },
      {
        name: 'readPolicy',
        path: '/privacypolicies/{id}/read/',
        method: 'POST',
        description: 'プライバシーpolicy既読',
        pathParams: [
          {
            paramName: 'id',
            require: true
          }
        ]
      },
      {
        name: 'wordExpansion',
        path: '/path/',
        method: 'GET',
        description: '語彙の展開',
        pathParams: []
      },
      {
        name: 'wordExpansionRoute',
        path: '/word/expand/',
        method: 'GET',
        description: '語彙の展開',
        pathParams: []
      },
      {
        name: 'queryExpansionRoute',
        path: '/query/expand/',
        method: 'GET',
        description: '語彙の展開(始点不明)',
        pathParams: []
      }
    ]
  }
}

export default Bundler
