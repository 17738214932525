import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import Box from '~/components/atoms/Box'
import LoginForm from '~/components/organisms/LoginForm'
import { requestPolicy } from '~/modules/policy/actions'
import { requestTerms } from '~/modules/terms/actions'

const AuthPage: React.FC = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(requestTerms())
    dispatch(requestPolicy())
  }, [dispatch])
  return (
    <Box>
      <LoginForm />
    </Box>
  )
}

export default AuthPage
