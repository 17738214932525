import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  TextField,
  Typography
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import React, { FC, useCallback, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { createGlobalStyle } from 'styled-components'

import PrivacyPolicyDialog from '~/components/organisms/PrivacyPolicyDialog'
import UsageDialog from '~/components/organisms/UsageDialog'
import { requestLogin } from '~/modules/auth/actions'
import { RootState } from '~/modules/reducer'
import { safeEncode } from '~/utils/search'

const GlobalStyle = createGlobalStyle`
  html {
    background-image: url('${process.env.PUBLIC_URL}/background.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    background-size: cover;
  }
`

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const Frame = styled(Card)`
  margin: 8px;
  max-width: 420px;
`

const Alerts = styled.div`
  & > * + * {
    margin-top: 8px;
  }
`

const LoginButtonFrame = styled.div`
  margin: -8px 8px 0 8px;
  width: 100%;
`

const TermsButtonFrame = styled.div`
  margin: 8px 16px 18px;
`

const NoticeForMembers = styled.p`
  color: rgba(0, 0, 0, 0.54);
  font-size: 0.9rem;
  margin: 8px 16px 28px;
`

const Header = styled.div`
  margin: 16px 0px 16px;
`

const TitleLogoTypography = styled(Typography)`
  text-align: center;
  font-size: 2.5rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`

const TitleLogo = styled.img`
  margin: 0.4rem;
  width: 1.5em;
  height: 1.5em;
`

const TitleTypography = styled.img`
  margin: 0.4rem;
  height: 1.8em;
  max-width: 100%;
`

type LoginFormProps = {}

const LoginForm: FC<LoginFormProps> = () => {
  const dispatch = useDispatch()

  const { nonFieldErrors, username, password } = useSelector(
    (state: RootState) => state.auth
  )

  // TODO input からのキャッチアップは来週以降ちゃんとかく
  const usernameRef = useRef<HTMLInputElement | null>(null)
  const passwordRef = useRef<HTMLInputElement | null>(null)

  const clickHandler = useCallback(() => {
    let username = ''
    let password = ''
    if (usernameRef && usernameRef.current) {
      username = (usernameRef.current as HTMLInputElement).value
    }
    if (passwordRef && passwordRef.current) {
      password = (passwordRef.current as HTMLInputElement).value
    }

    dispatch(requestLogin(username, password))
  }, [usernameRef, passwordRef])

  const [isTermsDialogOpen, setTermsDialogOpen] = useState(false)
  const [isPrivacyPolicyDialogOpen, setPrivacyPolicyDialogOpen] =
    useState(false)

  return (
    <>
      <GlobalStyle />
      <Container>
        <Frame>
          <CardContent>
            <Header>
              <TitleLogoTypography variant="h2">
                <TitleLogo src={`${process.env.PUBLIC_URL}/logo.png`} />
                <TitleTypography
                  src={`${process.env.PUBLIC_URL}/logo-typography.svg`}
                />
              </TitleLogoTypography>
            </Header>
            <Alerts>
              {nonFieldErrors &&
                nonFieldErrors.length > 0 &&
                nonFieldErrors.map((e) => (
                  <Alert severity="error" key={`auth-error-${safeEncode(e)}`}>
                    {e}
                  </Alert>
                ))}
            </Alerts>
            <TextField
              label="ユーザー名"
              name="username"
              margin="normal"
              variant="outlined"
              fullWidth
              inputRef={usernameRef}
              inputProps={{
                autoCapitalize: 'none'
              }}
              error={!!username}
              helperText={!!username && username}
            />
            <TextField
              label="パスワード"
              type="password"
              name="password"
              margin="normal"
              variant="outlined"
              fullWidth
              inputRef={passwordRef}
              error={!!password}
              helperText={!!password && password}
            />
          </CardContent>
          <CardActions>
            <LoginButtonFrame>
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                onClick={clickHandler}
              >
                利用規約に同意してログイン
              </Button>
            </LoginButtonFrame>
          </CardActions>
          <TermsButtonFrame>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  fullWidth
                  onClick={() => {
                    setTermsDialogOpen(true)
                  }}
                >
                  利用規約
                </Button>
              </Grid>
            </Grid>
          </TermsButtonFrame>
          <NoticeForMembers>
            日本内科学会会員のパーソナルウェブと同じIDとパスワードでログインしてご利用ください。
          </NoticeForMembers>
        </Frame>
        <UsageDialog
          open={isTermsDialogOpen}
          content="terms"
          onClose={() => {
            setTermsDialogOpen(false)
          }}
        />
        <PrivacyPolicyDialog
          open={isPrivacyPolicyDialogOpen}
          onClose={() => {
            setPrivacyPolicyDialogOpen(false)
          }}
        />
      </Container>
    </>
  )
}

export default LoginForm
