import { put } from 'redux-saga/effects'

// import { FetchQueue } from '~/service/api/queue'
import { DSSRequest } from '~/types/case'
import { RequestContext } from '~/types/network'

import APIService from '../../service/api'
import { Action, RequestPayload } from '../core/actions'
import { getToken } from '../core/saga'
import {
  dss2Actions,
  dss2DiseaseActions,
  DSS2DiseaseRequestPayload,
  dss2V1Actions
} from './actions'

/**
 * カテゴリ1取得
 */
export function* getDSS2V1(args: Action<RequestPayload<DSSRequest>>) {
  // @ts-expect-error: TS7057: 'yield' expression implicitly results in an 'any'
  const token = yield getToken()

  const req: RequestContext<DSSRequest> = {
    body: args.payload.body,

    token
  }
  let response
  // リクエスト実行
  try {
    // @ts-expect-error: TS7057: 'yield' expression implicitly results in an 'any'
    response = yield APIService['searchCat2'](req)
    // response = yield FetchQueue(APIService['searchCat2'], req)
  } catch (error) {
    response = error
  }

  if (response.error) {
    yield put(dss2V1Actions.failureDSS2V1({}))
  } else {
    yield put(dss2V1Actions.successDSS2V1({ data: response }))
  }
}

export function* getDSS2(args: Action<RequestPayload<DSSRequest>>) {
  // @ts-expect-error: TS7057: 'yield' expression implicitly results in an 'any'
  const token = yield getToken()

  const req: RequestContext<DSSRequest> = {
    body: args.payload.body,
    token
  }
  let response
  // リクエスト実行
  try {
    // @ts-expect-error: TS7057: 'yield' expression implicitly results in an 'any'
    response = yield APIService['searchCat2V2'](req)
    // response = yield FetchQueue(APIService['searchCat2V2'], req)
  } catch (error) {
    response = error
  }

  if (response.error) {
    yield put(dss2Actions.failureDSS2({}))
  } else {
    yield put(dss2Actions.successDSS2({ data: response }))
  }
}

export function* getDSS2Disease(args: Action<DSS2DiseaseRequestPayload>) {
  // @ts-expect-error: TS7057: 'yield' expression implicitly results in an 'any'
  const token = yield getToken()
  type Req = {
    q: string
  }
  const req: RequestContext<Req> = {
    body: { q: args.payload.body.q },
    token,
    pathParams: {
      id: args.payload.body.id
    }
  }
  let response
  // リクエスト実行
  try {
    // @ts-expect-error: TS7057: 'yield' expression implicitly results in an 'any'
    response = yield APIService['searchCat2V2Disease'](req)
  } catch (error) {
    response = error
  }

  if (response.error) {
    yield put(
      dss2DiseaseActions.failureDSS2Disease({ key: args.payload.body.id })
    )
  } else {
    yield put(dss2DiseaseActions.successDSS2Disease({ data: response }))
  }
}
