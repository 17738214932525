import React, { FC, Fragment, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { usePrevious } from 'rooks'

export type LocationObserverProps = {
  handlers?: ((pathname: string, prevpath: string) => void)[]
}

/**
 * LocationObserver
 * ロケーションの変化を検知して渡された任意の関数を実行する
 */
const LocationObserver: FC<LocationObserverProps> = ({
  children,
  handlers
}) => {
  const location = useLocation()
  const prevPathname = usePrevious(location.pathname)

  useEffect(() => {
    if (handlers) {
      handlers.forEach((handler) => {
        handler(location.pathname, prevPathname)
      })
    }
  }, [location, prevPathname])

  return <Fragment>{children}</Fragment>
}

export default LocationObserver
